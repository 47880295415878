import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import { useForm } from "react-hook-form";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

function AddWalletModal({ adamClient, walletAdded, accessToken, setDeletedWallet }) {
    const { register, handleSubmit, reset } = useForm();

    const [show, setShow] = useState(false);
    const [wallets, setWallets] = useState(adamClient.adamWallets)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clientService = new ClientService(accessToken);

    const onWalletAddSubmit = (data) => {
        console.log('AddWalletModal() => onWalletAddSubmit => data ', data)
        data.adamClientId = adamClient.id;

        // Temporary fix; wallet name seems to be the same as wallet type for now
        data.wallet_type = data.wallet_name;

        clientService.addWalletTask(data).then(response => {
            walletAdded(response);
            setShow(false);
            // clear the modal fields upon submission
            reset();
        });
    };

    const handleWalletDelete = wallet => {
        if(window.confirm(`Confirm that want to delete this wallet address: ${wallet.wallet_address}`)) {
            clientService.deleteWalletTask(wallet.id).then(() => {
                setDeletedWallet(wallet.id)
                setShow(false)
                reset()
            })
        }
    }

    let new_wallet = {};
    new_wallet.wallet_name = '';
    new_wallet.wallet_address = '';

    useEffect(() => {
        setWallets(adamClient.adamWallets)
    }, [ adamClient, show ])

    return (
        <>
            <Button size="small" variant="contained" color='info' onClick={handleShow}>
                Add Wallet
            </Button>
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                    <form onSubmit={handleSubmit(onWalletAddSubmit)}>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client Name:</label>
                                <p>{adamClient.clientPrimaryName}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="wallet_name" style={{fontWeight: 'bold'}}>Wallet Source</label>
                                <select {...register("wallet_name")} className="form-control" name="wallet_name" id="wallet_name">
                                    <option value="">Select...</option>
                                    <option value="Fireblocks">Fireblocks</option>
                                    <option value="External wallet">External wallet</option>
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6" style={{fontWeight: 'bold'}}>
                                <label htmlFor="wallet_address">Wallet Address</label>
                                <input {...register("wallet_address")} type="text" className="form-control" defaultValue={new_wallet.wallet_address} name="wallet_address" id="wallet_address" placeholder="Wallet Address" />
                            </div>
                        </div>
                        <div className="btncenter">
                            <input type="submit" className="btn btn-danger" />
                        </div>
                    </form>
                    <div>
                        {wallets.map(wallet => (
                            <div key={wallet.id} style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: 1 }}>
                                    <p>Name: {wallet.wallet_name}<br />Address: {wallet.wallet_address}</p>
                                </div>
                                <div>
                                    <button onClick={() => handleWalletDelete(wallet)}>Delete</button>
                                </div>
                            </div>
                        ))}
                        </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AddWalletModal;
