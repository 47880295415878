import React, {useState, useMemo} from 'react';
import InfoIcon from '@mui/icons-material/Info';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';

import { Paper, TableContainer, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, Stack } from '@mui/material';
function ContractsTable({data,wacData, loading, asset}) {
    const [order, setOrder] = useState('asc');
    console.log('wacContracts', data)
    console.log('wacData', wacData)
    const handleOpenContractDetailDialog = (row, index) => {
        
    }
    const handleRequestSort = (event) => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    }
    const RawDataRow = (rawTradeDataRow, index) => {
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <TableCell align='left'>{(new Date(rawTradeDataRow.createdAt).toLocaleString())}</TableCell>
                <TableCell>{rawTradeDataRow.source}</TableCell>
                <TableCell>{rawTradeDataRow.contractId}</TableCell>
                <TableCell align='left'>
                    {rawTradeDataRow.type}
                </TableCell>
                <TableCell align='left'>{rawTradeDataRow.type == 'IN' ? parseFloat(rawTradeDataRow.unitsIn).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : parseFloat(rawTradeDataRow.unitsOut).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</TableCell>
                <TableCell align='left'>
                    {rawTradeDataRow.type == 'IN'? parseFloat(rawTradeDataRow.rateIn).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : '0'}
                </TableCell>
                <TableCell align='left'>
                    {rawTradeDataRow.type == 'IN'? '0': parseFloat(rawTradeDataRow.rateOut).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}
                </TableCell>
                <TableCell align='left'>
                    {rawTradeDataRow.type == 'IN'? parseFloat(rawTradeDataRow.unitsIn).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : ''}
                </TableCell>
                <TableCell align='left'>
                    {rawTradeDataRow.type == 'IN'? '': '-' + parseFloat(rawTradeDataRow.unitsOut).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}
                </TableCell>
                <TableCell align='left'>
                    <span style={rawTradeDataRow.type == 'IN'?{color: 'green'}:{color: 'red'}}>{rawTradeDataRow.type == 'IN'? parseFloat(rawTradeDataRow.valueIn).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : parseFloat(rawTradeDataRow.valueOut).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</span>
                </TableCell>
                <TableCell align='left'>{parseFloat(rawTradeDataRow.adamWacLog.assetQuantityAfter).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</TableCell>
                {/* <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenContractDetailDialog(rawTradeDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell> */}
            </TableRow>
        )
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function descendingComparator(a, b) {
        // (new Date(rawTradeDataRow.createdAt).toLocaleString())
        if (new Date(b.createdAt) < new Date(a.createdAt)) {
            return -1;
        }
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
            return 1;
        }
        return 0;
    }
    function getComparator(order) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b)
            : (a, b) => -descendingComparator(a, b);
    }
    const contractTable = stableSort(data, getComparator(order))
        //.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map((rawTradeDataRow,index) => RawDataRow(rawTradeDataRow,index))
    // const visibleRows = useMemo(
    //     () =>
    //         stableSort(data, getComparator(order))
    //     [order, data],
    //     );
        
    if(data.length === 0) return null
    return (
        <div>
            <Stack direction="row" spacing={2} style={{margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div><span style={{fontWeight: 'bold'}}>WAC:</span> ${parseFloat(wacData.weightedAvgCost).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</div>
                <div><span style={{fontWeight: 'bold'}}>Inventory Available:</span> {parseFloat(wacData.assetQuantity).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</div>
                <div><span style={{fontWeight: 'bold'}}>USD Amount:</span> ${parseFloat(wacData.totalUsdVal).toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4})}</div>
                {/* <div><span style={{fontWeight: 'bold'}}>test wac:</span> {parseFloat(wacData.totalUsdVal)/parseFloat(wacData.assetQuantity)}</div> */}
            </Stack>
            <Paper>
                <TableContainer sx={{ maxHeight: 400, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={true}
                                        direction={order}
                                        onClick={() => handleRequestSort()}
                                    >
                                    Timestamp
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell>Contract Number</TableCell>
                                <TableCell align="left">IN/OUT</TableCell>
                                <TableCell align="left">Units</TableCell>
                                <TableCell align="left">Rate IN</TableCell>
                                <TableCell align="left">Rate OUT</TableCell>
                                <TableCell align="left">IN</TableCell>
                                <TableCell align="left">OUT</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Balance</TableCell>
                                {/* <TableCell align="left"/> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contractTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <SimpleTablePagination paginationProps={paginationProps} /> */}
            </Paper>     
        </div>
    );
}

export default ContractsTable;