import React, { useState, useEffect } from 'react';
import FileUpload from './FileUpload';
import axios from 'axios';

function UploadFortressCSV({ accessToken }) {
    const [uploadResponse, setUploadResponse] = useState(null);

    const handleFileDropped = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/fortressCSV', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setUploadResponse(response.data.message);  // Set the response message on successful upload
            
        } catch (error) {
            console.error("There was an error uploading the file.", error);
            setUploadResponse("Error uploading the file. Please try again.");  // Set an error message on upload failure
        }
    };
    
    return (
        <div>
            <h2>Upload your Fortress bank CSV</h2>
            <FileUpload onFileDropped={handleFileDropped} />
            {uploadResponse && (
                <div
                    className="upload-response"
                    style={{
                        color: uploadResponse.startsWith("Error") ? "red" : "green",
                        marginTop: "10px"
                    }}
                >
                    {uploadResponse}
                </div>
            )}
        </div>
    );
}

export default UploadFortressCSV;
