import React, { useState, useEffect } from 'react';
import RawBcbData from './RawBcbData';

import { BcbService } from '../../../api/BcbService';
import { ClientService } from '../../../api/ClientService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';

import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'

function BCB({ accessToken }) {
    const [rawBcb, setRawBcb] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0);

    const bcbService = new BcbService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const accountIDPerTab = [
        undefined,
        '21870',
        '21866',
        '23143',
        '21951',
        '21950',
        '23147'
    ]

    useEffect(() => {
        setLoading(true)

        bcbService.getAllBcb(paginationProps.pageSize, paginationProps.pageIndex, accountIDPerTab[activeTab]).then(rawBcb => {
            setRawBcb(rawBcb.data.sort((a, b) => b.value_date.localeCompare(a.value_date)));
            paginationProps.setTotalItemCount(rawBcb.totalItems)
            
            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize, activeTab])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        paginationProps.setPageIndex(0)
    }

    const updateRawBCBEntry = (transactionID, newData) => {

        // Use ID instead of index directly to handle filtered transaction lists
        const index = rawBcb.findIndex(transaction => transaction.id === transactionID)

        setRawBcb(oldRawBcb => [
            ...oldRawBcb.slice(0, index),
            newData,
            ...oldRawBcb.slice(index + 1)
        ])
    }
    
    return (
        // <div>
        //     <RawBcbData
        //         rawBcb={rawBcb}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="CAD" />
                <Tab label="EUR" />
                <Tab label="GBP" />
                <Tab label="CAD-BLINC" />
                <Tab label="EUR-BLINC" />
                <Tab label="GBP-BLINC" />
            </Tabs>
            <div style={{width: '100%'}}>
                <RawBcbData rawBcb={rawBcb} loading={loading || clientsLoading} clients={clients} 
                    setBCBClient={(bcbID, clientCopperID) => bcbService.setBcbClient(bcbID, clientCopperID)} updateRawBCBEntry={updateRawBCBEntry}
                    paginationProps={paginationProps} />
            </div>
        </Box>
    );
}

export default BCB;