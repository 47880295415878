import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EditFeeModal from './EditFeeModal';
import AddWalletModal from './AddWalletModal';
import AddClientnameModal from './AddClientnameModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Button, TextField, Autocomplete, FormControl, CircularProgress } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import CustomizedMultiSelect from '../../../components/CustomizedMultiSelect';
import { queue } from 'async'
import AdjustBalancesModal from './AdjustBalancesModal';

const ENTITIES_LIST =['SDM', 'GL']
const BANK_LIST = ['BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd', 'PrimeTrust', 'Arival']

const settledStatusRequestThrottler = queue(({ task, abortController }, completed) => {
    task(abortController).then(() => completed())
        .catch(err => console.error(err))
}, 3)

function SettledStatus({ clientCopperId, clientService }) {
    const [ loading, setLoading ] = useState(true)
    const [ balanceData, setBalanceData ] = useState()

    const loadStatus = async abortController => {
        const balances = await clientService.getClientBalanceData(clientCopperId, abortController)

        setBalanceData(balances)
        setLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()

        settledStatusRequestThrottler.push({
            abortController,
            task: loadStatus
        }, error => {
            if(error) {
                console.error(error)
                return
            }
        })
        
        return () => {
            abortController.abort()
            settledStatusRequestThrottler.remove()
        }
    }, [clientCopperId])
    
    if(loading) {
        return <CircularProgress />
    }

    // Safe to compare with 0 since amount was calculated using big decimal
    const settled = Object.values(balanceData.balances).every(amount => parseFloat(amount.replace(/[^0-9.-]+/g, '')) === 0.0)

    return settled ? (
        <b>Settled</b>
    )
    :
    (
        <Stack direction='column'>
            <b>Unsettled</b>
            {
                Object.entries(balanceData.balances)
                    .filter(([ _, amount ]) => parseFloat(amount.replace(/[^0-9.-]+/g, '')) !== 0.0)
                    .map(([ asset, amount ]) => (
                        <b>{amount} {asset}</b>
                    ))
            }
        </Stack>
    )
}

function Tasks({ tasks, deleteClient, feeEdited, walletAdded, clientnameAdded, loading, update, setUpdate, accessToken, setDeletedWallet, balanceUpdate, setBalanceUpdate }) {
    const [isEditing, setIsEditing] = useState(-1);
    const [clientOnEdit, setClientOnEdit] = useState();
    const [isEditingBank, setIsEditingBank] = useState(-1);
    const [selectedBanks, setSelectedBanks] = useState([])
    const clientService = new ClientService(accessToken);

    useEffect(() => () => settledStatusRequestThrottler.kill(), [])

    const handleEditClientEntity = (index, client) => {
        setIsEditing(index)
        setClientOnEdit(client)
        //console.log(ENTITIES_LIST.includes(clientOnEdit.entityForClient))
    }

    const handleClientEntityOnChange = (e, newValue) => {
        // console.log(newValue)
        let client = {...clientOnEdit}
        client.entityForClient = newValue.join(', ')
        setClientOnEdit(client)
    }

    const handleCloseEditingClientEnity = () => {
        setIsEditing(-1)
    }

    const handleEditClientBanks = (index, client) => {
        setIsEditingBank(index)
        setClientOnEdit(client)
        let banks = client.adamClientBanks.map(bank => bank.bank)
        // console.log(banks)
        setSelectedBanks(banks)
    }

    const handleCloseEditingClientBanks = () => {
        setIsEditingBank(-1)
        setSelectedBanks([])
    }

    const onConfirmUpdateClientEntity = async(clientId, newEntity) => {
        try{
            let counter = update + 1
            let result = await clientService.updateClientEnity(clientId, newEntity)
            // console.log(result);
            if(result[0] === 1) {
                setUpdate(counter)
                handleCloseEditingClientEnity()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
        
    }

    const onConfirmUpdateClientBanks = async(clientId, existingBanks) => {
        // console.log(existingBanks)
        // console.log(selectedBanks)

        // let removedBanks = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        // console.log(removedBanks)
        // let existedBank = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        try{
            let counter = update + 1
            let result = await clientService.updateClientBanks(clientId, selectedBanks, existingBanks)
            // console.log(result)
            if(result) {
                setUpdate(counter)
                handleCloseEditingClientBanks()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
    }

    const handleClientBankSelectionChange = (e, value) => {
        // console.log(value)
        setSelectedBanks(value)
    }

    const TaskRow = (task, index) => {
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <TableCell>{task.clientCopperId}</TableCell>
                <TableCell align='left'>
                    <Link to={`/clientDetails/${task.clientCopperId}`}>
                        {task.clientPrimaryName}
                    </Link>
                </TableCell>
                <TableCell align='left'>
                    {task.adamClientnames.map(name => (
                        <ul>{name.client_name} <span style={{fontSize: '11px', color: 'gray'}}>({name.name_source})</span></ul>
                    ))}
                </TableCell>
                {/* <TableCell align='left'>{task.clientCopperId}</TableCell> */}
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditing === index? 
                            <FormControl sx={{minWidth: 150 }}>
                                <CustomizedMultiSelect defaultValues={clientOnEdit.entityForClient ? clientOnEdit.entityForClient.split(', ').filter(str => str !== ''): []} options={ENTITIES_LIST} onChange={(e, v) => handleClientEntityOnChange(e, v)}/>
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={ENTITIES_LIST}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={clientOnEdit.entityForClient}
                                    onChange={(e, v) => handleClientEntityOnChange(v)}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder={task.entityForClient}/>}
                                /> */}
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{task.entityForClient}</div>}
                        {isEditing === index? 
                        <Stack direction={'row'}>
                            <Button onClick={() => onConfirmUpdateClientEntity(clientOnEdit.id, clientOnEdit.entityForClient)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingClientEnity()}>Cancel</Button>
                        </Stack>
                        :<Button onClick={() => handleEditClientEntity(index, task)}>Edit</Button>}
                    </Stack>
                </TableCell>
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditingBank === index ?
                            <FormControl sx={{minWidth: 150 }}>
                                <CustomizedMultiSelect defaultValues={selectedBanks} options={BANK_LIST} onChange={(e, v) => handleClientBankSelectionChange(e, v)}/>
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{task.adamClientBanks.map(bankObj => bankObj.bank).join(', ')}</div>}
                        {isEditingBank === index? 
                        <Stack direction={'row'}>
                            <Button disabled={task.adamClientBanks.map(bankObj => bankObj.bank).length === 0 && selectedBanks.length === 0} onClick={() => onConfirmUpdateClientBanks(clientOnEdit.id, clientOnEdit.adamClientBanks)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingClientBanks()}>Cancel</Button>
                        </Stack>
                            :<Button onClick={() => handleEditClientBanks(index, task)}>Edit</Button>}
                    </Stack>
                    
                </TableCell>
                <TableCell align='left'>{task.adamClientFee ? parseFloat(task.adamClientFee.fee) + "%" : ''}</TableCell>
                <TableCell align='left'><SettledStatus clientCopperId={task.clientCopperId} clientService={clientService} /></TableCell>
                {/* <TableCell align='left'>{task.adamClientnames.length}</TableCell>
                <TableCell align='left'>{task.adamWallets.length}</TableCell> */}
                <TableCell align='left'>
                    <Stack spacing={1}>
                        <EditFeeModal
                            adamClient={task}
                            feeEdited={feeEdited}
                            update={update}
                            setUpdate={setUpdate}
                            accessToken={accessToken}
                        />
                        <AddWalletModal
                            adamClient={task}
                            walletAdded={walletAdded}
                            setDeletedWallet={setDeletedWallet}
                            update={update}
                            setUpdate={setUpdate}
                            accessToken={accessToken}
                        />
                        <AddClientnameModal
                            adamClient={task}
                            clientnameAdded={clientnameAdded}
                            update={update}
                            setUpdate={setUpdate}
                            accessToken={accessToken}
                        />
                        <AdjustBalancesModal
                            adamClient={task}
                            accessToken={accessToken}
                            balanceUpdate={balanceUpdate}
                            setBalanceUpdate={setBalanceUpdate}
                        />
                        {/* <Button variant="contained" color='error' onClick={(e) => deleteClient(task.id)}>Delete</Button> */}
                    </Stack>
                </TableCell>
            </TableRow>
        )
    }
    const clientTable = tasks.map((task,index) => TaskRow(task,index))
    if (tasks.length === 0) return null
    else return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Copper ID</TableCell>
                            <TableCell align="left">Client's Primary Name</TableCell>
                            <TableCell align="left">Client's Alternate Names</TableCell>
                            {/* <TableCell align="left">Client's Copper ID</TableCell> */}
                            <TableCell align="left">Our Entity for Client</TableCell>
                            <TableCell align="left">Bank</TableCell>
                            <TableCell align="left">Client's Commission Fee</TableCell>
                            {/* <TableCell align="left">Alternate Names</TableCell>
                            <TableCell align="left">Wallets</TableCell> */}
                            <TableCell align="left">Settled/Unsettled</TableCell>
                            <TableCell align="left">Actions</TableCell>    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientTable}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default Tasks;