/**
 * FortressService Service
 */
export class FortressService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getAllFortress(pageSize, pageNum) {
        const response = await fetch(
            this.API_URL + `/fortress?size=${pageSize}&page=${pageNum}`,
            {
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async setFortressClient(fortressTxId, clientCopperID) {
        const response = await fetch(
            this.API_URL + '/fortress/setclient',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                },
                body: JSON.stringify({
                    fortressTxId,
                    clientCopperID
                })
            }
        );
        return response.json();
    }

}   