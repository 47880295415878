import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, TextField, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import axios from "axios";

function TradingPairManager({ accessToken }){
  const API_URL = process.env.REACT_APP_API_URL;
  const [tradingPairs, setTradingPairs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [newPair, setNewPair] = useState("");
  const [editTradingPair, setEditTradingPair] = useState(null);
  const [editPair, setEditPair] = useState("");

  useEffect(() => {
    fetchTradingPairs();
  }, []);

  const fetchTradingPairs = async () => {
    try {
      const response = await axios.get(API_URL + '/tradingpairs', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setTradingPairs(response.data);
    } catch (error) {
      setIsError(true);
      setErrorMsg('Failed to fetch trading pairs.');
    }
  };

  const handleAddTradingPair = async () => {
    try {
      await axios.post(
        API_URL + '/tradingpairs',
        { trading_pair: newPair },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setSuccessMsg('Trading pair added successfully.');
      setIsSuccess(true);
      setNewPair("");
      fetchTradingPairs();
    } catch (error) {
      setIsError(true);
      setErrorMsg('Failed to add trading pair.');
    }
  };

  const handleDeleteTradingPair = async (id) => {
    try {
      await axios.delete(
        API_URL + '/tradingpairs/' + id,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setSuccessMsg('Trading pair deleted successfully.');
      setIsSuccess(true);
      fetchTradingPairs();
    } catch (error) {
      setIsError(true);
      setErrorMsg('Failed to delete trading pair.');
    }
  };

  const handleEditTradingPair = async () => {
    try {
      await axios.put(
        API_URL + '/tradingpairs',
        { id: editTradingPair.id, trading_pair: editPair },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setSuccessMsg('Trading pair updated successfully.');
      setIsSuccess(true);
      setEditPair("");
      setEditTradingPair(null);
      fetchTradingPairs();
    } catch (error) {
      setIsError(true);
      setErrorMsg('Failed to update trading pair.');
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "trading_pair", headerName: "Trading Pair", width: 200 },
    {
        field: "edit",
        headerName: "Edit",
        width: 120,
        renderCell: (params) => (
            <Button onClick={() => { setEditTradingPair(params.row); setEditPair(params.row.trading_pair); }} variant="outlined" color="primary">
            Edit
            </Button>
        ),
    },{
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <Button onClick={() => handleDeleteTradingPair(params.row.id)} variant="outlined" color="error">
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: '600px', width: '50%', margin: 'auto' }}>
      <Box>
        <TextField value={newPair} onChange={(e) => setNewPair(e.target.value)} />
        <Button onClick={handleAddTradingPair} variant="outlined" color="primary">
          Add Trading Pair
        </Button>
      </Box>
      <DataGrid rows={tradingPairs} columns={columns} pageSize={5} />
      <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
        <Alert severity="error" onClose={() => setIsError(false)}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
        <Alert severity="success" onClose={() => setIsSuccess(false)}>
          {successMsg}
        </Alert>
      </Snackbar>
      <Dialog open={editTradingPair !== null} onClose={() => setEditTradingPair(null)}>
        <DialogTitle>Edit Trading Pair</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Trading Pair"
            value={editPair}
            onChange={(e) => setEditPair(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTradingPair(null)} color="primary">Cancel</Button>
          <Button onClick={handleEditTradingPair} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TradingPairManager;
