import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { ClientService } from '../../../api/ClientService';
import { Stack, FormControl, Button, Select, MenuItem, Autocomplete, TextField, IconButton } from '@mui/material';
import CustomizedMultiSelect from '../../../components/CustomizedMultiSelect';
import SearchIcon from '@mui/icons-material/Search';
import SimpleConfirmationTooltip from '../../../components/SimpleConfirmationTooltip';

function FireblocksWalletList({ accessToken, rawWalletData, clients, types, currencyList, update, setUpdate, loading, setIsError, setErrorMsg, setIsSuccess, setSuccessMsg }) {
    // const [loading, setLoading] = useState(true);
    // const [rawWalletData, setRawWalletData] = useState([])
    const [curTxIndex, setCurTxIndex] = useState(-1);
    // const [openTxInfo, setOpenTxInfo] = useState(false);
    const [isEditing, setIsEditing] = useState(-1);
    const [walletOnEdit, setWalletOnEdit] = useState();
    // const [types, setTypes] = useState([])
    // const [currencyList, setCurrencyList] = useState([])
    // const [clients, setClients] = useState([])
    const [typeFilter, setTypeFilter] = useState('All');
    const [currencyFilter, setCurrencyFilter] = useState('All');
    const [selectedClientId, setSelectedClientId] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(null);
    // const [txData, setTxData] = useState();

    const clientService = new ClientService(accessToken);

    const handleSearchClick = () => {
        setIsSearchOpen(!isSearchOpen);
        setSearchValue('');
    };
    
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };
    // useEffect(() => {
    //     getData()
    // }, [])
    // const getData = async() => {
    //     let res = await clientService.getAllSourceWallets()
    //     console.log(res)
    //     let walletTypes = res.map(w => w.type)
    //     walletTypes = [...new Set(walletTypes)]
    //     let currencies = res.map(w => w.currency)
    //     currencies = [...new Set(currencies)]
    //     setCurrencyList(currencies)
    //     await getClients()
    //     setTypes(walletTypes)
    //     setRawWalletData(res)
    //     setLoading(false)
    // }
    // const getClients = async() => {
    //     let res = await clientService.getAllClients()
    //     setClients(res)
    //     console.log(res)
    // }

    const handleClientMappingOnChange = (e, v) => {
        console.log(v)
        let selected = clients.filter(c => c.clientPrimaryName == v)[0].id
        console.log(selected)
        setSelectedClientId(selected)
    }

    const onConfirmUpdateWalletMapping = async(walletId) => {
        try {
            let counter = update + 1
            let res = await clientService.updateFBWalletClientRelationship(selectedClientId, walletId)
            console.log(res)
            if(res[0] == 1) {
                setUpdate(counter)
                handleCloseEditingWalletMapping()
                setIsError(false)
                setIsSuccess(true)
                setSuccessMsg("Update client wallet mapping successful")
            }
            else {
                setIsSuccess(false)
                setIsError(true)
                setErrorMsg("Update client wallet mapping failed")
            }
        }
        catch(err){
            console.log(err)
            setIsSuccess(false)
                setIsError(true)
                setErrorMsg("Update client wallet mapping failed")
        }
    }

    const handleDeleteWallet = async walletId => {
        try {
            const deleted = await clientService.deleteClientFBWallet(walletId)

            if(deleted) {
                setUpdate(prevUpdate => prevUpdate + 1)
                handleCloseEditingWalletMapping()
                setIsError(false)
                setIsSuccess(true)
                setSuccessMsg("Delete client wallet mapping successful")
            }
            else {
                setIsSuccess(false)
                setIsError(true)
                setErrorMsg("Delete client wallet mapping failed")
            }
        }
        catch(err) {
            console.error(err)

            setIsSuccess(false)
            setIsError(true)

            setErrorMsg("Delete client wallet mapping failed")
        }
    }

    const handleCloseEditingWalletMapping = () => {
        setIsEditing(-1)
    }
    const handleEditWalletMapping = (index, wallet) => {
        setIsEditing(index)
        setWalletOnEdit(wallet)
        setSelectedClientId(wallet.clientId)
    }
    const RawWalletDataRow = (rawWalletDataRow, index, handleDelete) => {
        const handleDeleteClick = () => {
            setDeleteTooltipOpen(false)
            handleDelete(rawWalletDataRow.id)
        }

        // console.log('rawFireblocksDataRow', rawFireblocksDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{rawWalletDataRow.name}</TableCell>
                <TableCell align='left'>{rawWalletDataRow.type}</TableCell>
                <TableCell align='left'>{rawWalletDataRow.walletAddress}</TableCell>
                <TableCell align='left'>{rawWalletDataRow.currency}</TableCell>
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditing === index? 
                            <FormControl sx={{minWidth: 300 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clients.map(client => client.clientPrimaryName)}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={selectedClientId && clients.filter(c => c.id == selectedClientId)[0].clientPrimaryName}
                                    onChange={(e, v) => handleClientMappingOnChange(e, v)}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder={selectedClientId && clients.filter(c => c.id == selectedClientId)[0].clientPrimaryName}/>}
                                />
                                {/* <CustomizedMultiSelect value={walletOnEdit.clientId} options={clients.map(client => client.clientPrimaryName)} onChange={(e, v) => handleClientMappingOnChange(e, v)}/> */}
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{rawWalletDataRow.clientId && clients.filter(c => c.id === rawWalletDataRow.clientId)[0].clientPrimaryName}</div>}
                        {isEditing === index? 
                        <Stack direction={'row'}>
                            {rawWalletDataRow.clientId && <Button onClick={() => setSelectedClientId(null)}>Unmap</Button>}
                            <Button onClick={() => onConfirmUpdateWalletMapping(rawWalletDataRow.id, walletOnEdit.clientId)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingWalletMapping()}>Cancel</Button>
                            <SimpleConfirmationTooltip
                                placement="left"
                                open={deleteTooltipOpen === rawWalletDataRow.id}
                                arrow
                                confirmMessage='Are you sure you want to delete this wallet?'
                                onClose={() => setDeleteTooltipOpen(null)}
                                onConfirm={() => handleDeleteClick()}
                            >
                                <Button color='error' onClick={() => setDeleteTooltipOpen(rawWalletDataRow.id)}>Delete</Button>
                            </SimpleConfirmationTooltip>
                        </Stack>
                        :<Button onClick={() => handleEditWalletMapping(index, rawWalletDataRow)}>Edit</Button>}
                    </Stack>
                </TableCell>
            </TableRow>
        )
    }
    const handleTypeFilterChange = (e) => {
        setTypeFilter(e.target.value)
    }
    const handleCurrencyFilterChange = (e) => {
        setCurrencyFilter(e.target.value)
    }

    const walletTable = rawWalletData
                            .filter(rw => typeFilter == 'All' ? rw : rw.type == typeFilter)
                            .filter(rw => currencyFilter == 'All' ? rw : rw.currency == currencyFilter)
                            .filter(rw => isSearchOpen? rw.walletAddress.toUpperCase().includes(searchValue.toUpperCase()) : rw)
                            .map((rawWalletDataRow,index) => RawWalletDataRow(rawWalletDataRow,index,handleDeleteWallet))
    return loading?
            (<CircularProgress/>)
            :(<div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>
                                <Select disabled={types.length === 0} variant='standard' value={typeFilter} onChange={handleTypeFilterChange} id="demo-simple-select-standard">
                                    <MenuItem value="All">All Types</MenuItem>
                                    {types.map(t => <MenuItem value={t}>{t}</MenuItem>)}
{/*                                     
                                    <MenuItem value="CAD">CAD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="GBP">GBP</MenuItem> */}
                                </Select>
                            </TableCell>
                            <TableCell align="left">
                                Address
                                <IconButton onClick={handleSearchClick} disabled={rawWalletData.length === 0}>
                                    <SearchIcon />
                                </IconButton>
                                {isSearchOpen && (
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                <Select disabled={types.length === 0} variant='standard' value={currencyFilter} onChange={handleCurrencyFilterChange} id="demo-simple-select-standard">
                                    <MenuItem value="All">All Currencies</MenuItem>
                                    {currencyList.map(t => <MenuItem value={t}>{t}</MenuItem>)}
{/*                                     
                                    <MenuItem value="CAD">CAD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="GBP">GBP</MenuItem> */}
                                </Select>
                            </TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {walletTable}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
}

export default FireblocksWalletList;