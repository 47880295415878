import React, {useEffect, useState, useRef} from 'react';
import ContractsTable from './ContractsTable';
import { WacService } from '../../../api/WacService';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';


// import { CSVLink } from "react-csv";
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
            },
            '&:active': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
            ),
            },
        },
    },
}));

const cryptoList = [
    "BTC",
    "ETH",
    "USDT",
    "USDC",
    "AVAX",
    "BNB",
    "ADA",
    "LINK",
    "DOGE",
    "LTC",
    "PAX",
    "DOT",
    "SHIB",
    "MATIC",
    "SOL",
    "ATOM",
    "FLR",
    "LTX",
    "BCH",
    "FTM",
    "TRX",
    "XRP",
    "RNDR",
    "CRE",
    "MANA",
    "WBTC",
    "BUSD",
    "MATIC",
    "XEC",
    "ETC",
    "CUSDC",
    "ETHW",
    "DAI"
];

function WAC({curUser, token}) {
    const wacService = new WacService(token)
    const [loading, setLoading] = useState(false);
    const [contractsData, setContractData] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [curSelectedAsset, setCurSelectedAsset] = useState('BTC');
    const [wacData, setWacData] = useState();
    const tableRef = useRef(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAssetChange = (event, index) => {
        console.log(cryptoList[index])
        setCurSelectedAsset(cryptoList[index])
        handleClose()
    }
    useEffect(() => {
        setLoading(true)
        wacService.getWACByAsset(curSelectedAsset).then(waxRes => {
            setWacData(waxRes);
        }).catch(err => {
            console.log(err)
        })
        wacService.getContractsByAsset(curSelectedAsset).then(rawContract => {
            setContractData(rawContract)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [curSelectedAsset]);
    const handleDownloadExcel = () => {
        let wacInfo = ['WAC:', parseFloat(wacData.weightedAvgCost).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'Inventory Available:', parseFloat(wacData.assetQuantity).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'USD Amount:', parseFloat(wacData.totalUsdVal).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})]
        let header = ["ID", "Contract Number", "IN/OUT", 'Units', 'Rate IN', 'Rate OUT', 'Value IN', 'Value OUT', 'Inventory Change', 'Balance'];
        let excelData = [wacInfo, header]
        contractsData.map(contract => {
            let objCon = {
                id: contract.id,
                contractNumber: contract.contractId,
                type: contract.type,
                units: contract.type === 'IN' ? 
                    parseFloat(contract.unitsIn) //.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                    : parseFloat(contract.unitsOut),//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}),
                rateIn: contract.type === 'IN' ? 
                    parseFloat(contract.rateIn)//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                    : 0,
                rateOut: contract.type === 'IN' ? 
                    0 
                    : parseFloat(contract.rateOut),//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}),
                in: contract.type === 'IN' ? 
                    parseFloat(contract.valueIn)//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                    : 0,
                out: contract.type === 'IN' ? 
                    0 
                    : -1 * parseFloat(contract.valueOut), //.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}),
                inventoryChange: parseFloat(contract.inventoryChange),//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}),
                balance: parseFloat(contract.adamWacLog.assetQuantityAfter)//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})
            }
            excelData.push(Object.values(objCon))
            return objCon
        })
        console.log(excelData)
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, curSelectedAsset);
        
        XLSX.writeFile(wb, "weighted-average-cost-"+curSelectedAsset + new Date().toISOString() + ".xlsx");
        // downloadExcel({
        //     fileName: "weighted-average-cost-"+curSelectedAsset+new Date().toISOString()+'.xls',
        //     sheet: curSelectedAsset,
        //     tablePayload: {
        //         header,
        //         // accept two different data structures
        //         body: body,
        //     },
        // });
    }
    return (
        <div>
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Asset {curSelectedAsset? ': ' + curSelectedAsset: null}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {cryptoList.map((c, index) => 
                        <MenuItem key={index} onClick={e => handleAssetChange(e, index)} disableRipple selected={curSelectedAsset == c}>
                            {c}
                        </MenuItem>
                    )}
                </StyledMenu>
            </div>
            {contractsData && wacData && <ContractsTable data={contractsData} wacData={wacData} loading={loading} asset={"BTC"}/>}
            <div style={{margin: 10}}>
                <button onClick={handleDownloadExcel}>download excel</button>
            </div>
            
        </div>
    );
}

export default WAC;