import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { TradeTicketService } from '../../../api/TradeTicketService';
import { WacService } from '../../../api/WacService';
import { getCurrentDateAsYYYYMMDD, getCurrentTimeZoneDateAsYYYYMMDD } from '../../utils/dateHelper';
import { getCurrentTradeTicketIDcount } from '../../utils/tradeTicketIDHelper';
import { Stack, Button, Select, MenuItem, FormControl, TextField, Autocomplete, Snackbar, Paper, Modal, Box, Typography, FormControlLabel, Checkbox, ListItemText } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TradeTicketsTable from './TradeTicketsTable';
import TradePreviewDialog from './TradePreviewDialog';
import { rates } from './rates';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import usePagination from '../../../hooks/usePagination';
import { useQuery, useMutation } from '@apollo/client';
import { POST_TRADE, GET_CLIENT_ID, GET_CUSTOMER_ID, INSERT_TRADING_ACCOUNT } from '../../../api/atlasQueries';
const GL_TRADING_ACCOUNT_ID = 'fae9b545-3cfd-4eea-8f77-3cd7a7396d3b'
const TRADING_CATEGORY = 'CUSTOMER_TRANSACTION';
const GL_TRADING_ACCOUNT_PROVIDER_ID = '3829fad9-4500-4d4b-b69e-2c9d75645491'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Trade Ticket Generator
function TradeTicketGenerator({curUser, showTable, token}) {
    const [askingTermsSelect, setAskingTermsSelect] = useState('Fiat'); // initial state for select input
    const askingTermsOptions = ['Fiat', 'Crypto']; // options in the select/pulldown
    const [client, setClient] = useState({})
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tradeTicketID, setTradeTicketID] = useState('');
    const [tradingPair, setTradingPair] = useState('BTC/USD');
    const [action, setAction] = useState('Buy');
    const [entity, setEntity] = useState('GL');
    const [amount, setAmount] = useState('0');
    const [displayAmount, setDisplayAmount] = useState('0')
    const [amountToTrade, setAmountToTrade] = useState('0');
    const [spread, setSpread] = useState('0');
    const [dynamicSpread, setDynamicSpread] = useState('0');
    const [coryCommission, setCoryCommission] = useState('0');
    const [coryCommissionAmt, setCoryCommissionAmt] = useState('0');

    const [feeType, setFeeType] = useState(0);
    const [spotRate, setSpotRate] = useState('0');
    const [displaySpotRate, setDisplaySpotRate] = useState('0');
    const [clientDealtRate, setClientDealtRate] = useState('0');
    const [update, setUpdate] = useState(0);
    const [clientOptions, setClientOptions] = useState([]);
    const [clientNameError, setClientNameError] = useState(null);
    const [markup, setMarkup] = useState('0');
    const [additionalCommission, setAdditionalCommission] = useState('0');
    const [totalCommission, setTotalCommission] = useState('0');
    // State for loading and error state of Spread/Fee API call
    const [spreadLoading, setSpreadLoading] = useState(false);
    const [spreadError, setSpreadError] = useState(null);
    // getting trades
    const [rawTrade, setRawTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false)
    // preview dialog
    const [showPreview, setShowPreview] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    // enable fee/ commission change
    const [enableFeeChange, setEnableFeeChange] = useState(false);
    const [feeChangeAlert, setFeeChangeAlert] = useState(false);
    const [enableCommissionChange, setEnableCommissionChange] = useState(false);
    const [commissionChangeAlert, setCommissionChangeAlert] = useState(false);
    
    const [decimalPlace, setDecimalPlace] = useState(6);
    const [dealtDecimals,setDealtDecimals] = useState(6);
    const [tradingPairOptions, setTradingPairOptions] = useState([]);   // options in the Trading Pairs select/pulldown
    const [assetInventory, setAssetInventory] = useState(0);
    const [assetOut, setAssetOut] = useState(0);
    // const [quoteAssetInventory, setQuoteAssetInventory] = useState(0);
    const [inventoryLowWarning, setInventoryLowWarning] = useState(false);
    const clientService = new ClientService(token);
    const tradeService = new TradeTicketService(token);
    const wacService = new WacService(token)
    // EXEC TICKET
    const [liquidityProvider, setLiquidityProvider] = useState('');
    const [execPrice, setExecPrice] = useState('0');
    const [platform, setPlatform] = useState('');
    const [orderId, setOrderId] = useState('');
    const paginationProps = usePagination()
    const [notPushToExecChat, setNotPushToExecChat] = useState(false);
    const [tradeTimestamp, setTradeTimestamp] = useState(dayjs());
    const [postTradeToAtlas] = useMutation(POST_TRADE);
    const [createNewAtlasTradingAccount] = useMutation(INSERT_TRADING_ACCOUNT);
    let {data: accountData} = useQuery(GET_CLIENT_ID, {variables: {ref_id: `GL:${clientId}`}})
    let {data: customerData} = useQuery(GET_CUSTOMER_ID, {variables: {ref_id: clientId}})
    const handleNotPushExecChange = (event) => {
        setNotPushToExecChat(event.target.checked);
    }
    useEffect(() => {
        console.log('atlas account id:', accountData);
        console.log('atlas customers id:', customerData);

    }, [accountData, customerData])
    // State for loading and error state of Trading Pairs API call
    useEffect(() => {
        const fetchTradingPairs = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + '/tradingpairs',
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const tradingPairs = data.map(item => ({
                    value: item.trading_pair,
                    label: item.trading_pair,
                })).sort((a, b) => a.value < b.value ? -1 : a.value > b.value ? 1 : 0);
                setTradingPairOptions(tradingPairs);
            } catch (error) {
                console.error('There was an error fetching trading pairs!', error);
            }
        };
    
        fetchTradingPairs();
    }, []);
    
    // Load all Trade Tickets and store them with setRawTrades
    useEffect(() => {
        setLoading(true)

        tradeService.getAllTradeTicket(paginationProps.pageSize, paginationProps.pageIndex).then(rawTrade => {
            setRawTrades(rawTrade.data)
            paginationProps.setTotalItemCount(rawTrade.totalItems)

            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [update, paginationProps.pageSize, paginationProps.pageIndex]);

    // State for loading and error state of Client Names API call
    useEffect(() => {
        // API fetching function
        const getClientNamesFromAPI = async () => {
            // Fetch data from API and return an array of client names
            const clientDataFromAPI = await clientService.getAllClients();
            // console.log(clientDataFromAPI);
            // console.log('clientDataFromAPI', clientDataFromAPI);
            let clientDataForAutocomplete = []; // initialize empty array

            // Loop through the data and format it for our autocomplete
            for (const aClientData of clientDataFromAPI) {
                let the_name = ''; // initialize empty string

                // iterate through aClientData.adamClientnames to look for name_source: 'TradeTicketName'
                for (const aClientname of aClientData.adamClientnames) {
                    if (aClientname.name_source === 'TradeTicketName') {
                        the_name = aClientname.client_name;
                        break;
                    }
                }

                // if we didn't find an adamClientname name_source: 'TradeTicketName', use the primary/Copper name
                if (the_name === '') {
                    the_name = aClientData.clientPrimaryName;
                }

                clientDataForAutocomplete.push({
                    name: the_name,
                    id: aClientData.id,
                    copperId: aClientData.clientCopperId
                });
            }
            // console.log('clientDataForAutocomplete', clientDataForAutocomplete);    
            // return ['Client 1', 'Client 2', 'Client 3']; // good for testing without API data
            return clientDataForAutocomplete;
        };
    
        getClientNamesFromAPI().then(data => {
            const options = data.sort((a,b) => a.name.localeCompare(b.name)).map(client => ({
                value: client.copperId, label: client.name, id: client.copperId, copperId: client.copperId 
            }));
            setClientOptions(options);
        });
    }, []);

    // Effect to fetch Spread/Fee when clientName changes
    useEffect(() => {
        // console.log('client', client);
        // console.log('clientName', clientName);
        if (!clientName) return; // Don't fetch if no client name selected
        setSpreadLoading(true);
        setSpreadError(null);
        clientService.getSpread(client.copperId)
            .then(response => {
                // console.log('/api/spread response:', parseFloat(response.fee));
                if(!isNaN(parseFloat(response.fee))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setSpread(parseFloat(response.fee));
                }
                else {
                    console.log('not a number')
                    setSpread("0")
                }
                if(!isNaN(parseFloat(response.dynamicFee))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setDynamicSpread(parseFloat(response.dynamicFee));
                }
                else {
                    console.log('not a number')
                    setDynamicSpread("0")
                }
                if(!isNaN(parseFloat(response.coryCommission))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setCoryCommission(parseFloat(response.coryCommission));
                }
                else {
                    setCoryCommission("0")
                }
                setSpreadLoading(false);
            })
            .catch(error => {
                setSpreadError(error.message);
                setSpreadLoading(false);
            });
    }, [clientName]);

    useEffect(() => {
        // console.log('spread', spread);
        // console.log('spotRate', spotRate);
        // console.log('action', action);
        if (spotRate && action) {
            let newClientDealtRate = 0;
            if (action === 'Buy') {
                newClientDealtRate = Number(spotRate) * (1 + Number(spread) / 100);
            } else if (action === 'Sell') {
                newClientDealtRate = Number(spotRate) * (1 - Number(spread) / 100);
            }
            askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            if (askingTermsSelect === 'Fiat') {
                setClientDealtRate(newClientDealtRate)//.toFixed(dealtDecimals));
            }
            else {
                setClientDealtRate(newClientDealtRate)//.toFixed(decimalPlace));
            }
        }
    }, [spread, spotRate, action, dealtDecimals]);

    // Event for auto-calculation of Amount to Trade
    useEffect(() => {
        if (amount && clientDealtRate && action && askingTermsSelect) {
            let newAmountToTrade = 0;
            if (askingTermsSelect === 'Fiat') {
                newAmountToTrade = Number(amount) / Number(clientDealtRate);
            } else if (askingTermsSelect === 'Crypto') {
                newAmountToTrade = Number(amount) * Number(clientDealtRate);
            }
            // console.log('newAmountToTrade', newAmountToTrade);
            setAmountToTrade(newAmountToTrade)//.toFixed(decimalPlace).toString());//.toFixed(2));
        }
    }, [amount, clientDealtRate, action, askingTermsSelect, decimalPlace]);

    // Automatically update TradeTicketID whenever Trading Pair or Action changes
    useEffect(() => {
        getCurrentTradeTicketIDcount().then((tradeTicketNumber) => {
            const [tradingPair1, tradingPair2] = tradingPair.split('/');
            let theTradeTicketID = entity;  // string to build Trade Ticket ID
            theTradeTicketID += getCurrentTimeZoneDateAsYYYYMMDD();
            theTradeTicketID += '-';
            theTradeTicketID += action.charAt(0);
            theTradeTicketID += '-';
            theTradeTicketID += tradingPair1;
            theTradeTicketID += tradingPair2;
            console.log(theTradeTicketID);
            // increment Trade Ticket number; last "current" number (tradeTicketNumber) comes as string!
            const currentTradeTicketNumber = 1 + Number(tradeTicketNumber);
            // Pad the tradeTicketNumber to 3 digits and convert back to string
            const paddedTradeTicketNumber = currentTradeTicketNumber.toString().padStart(3, '0');
            theTradeTicketID += paddedTradeTicketNumber;
            console.log('theTradeTicketID', theTradeTicketID);
            setTradeTicketID(theTradeTicketID);
        });
    }, [tradingPair, action, update, entity]);
    const getNewestTradeIdBeforeSubmission = async() => {
        let tradeTicketNumber = await getCurrentTradeTicketIDcount()
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        let theTradeTicketID = entity;  // string to build Trade Ticket ID
        theTradeTicketID += getCurrentTimeZoneDateAsYYYYMMDD();
        theTradeTicketID += '-';
        theTradeTicketID += action.charAt(0);
        theTradeTicketID += '-';
        theTradeTicketID += tradingPair1;
        theTradeTicketID += tradingPair2;
        console.log(theTradeTicketID);
        // increment Trade Ticket number; last "current" number (tradeTicketNumber) comes as string!
        const currentTradeTicketNumber = 1 + Number(tradeTicketNumber);
        // Pad the tradeTicketNumber to 3 digits and convert back to string
        const paddedTradeTicketNumber = currentTradeTicketNumber.toString().padStart(3, '0');
        theTradeTicketID += paddedTradeTicketNumber;
        console.log('theTradeTicketID', theTradeTicketID);
        return (theTradeTicketID);
    }
    useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(tradingPair1 && tradingPair2){
            let rate = rates[tradingPair1] ? rates[tradingPair1].decimal : 2
            let dealt = rates[tradingPair1] ? rates[tradingPair1][tradingPair] : 4
            if (askingTermsSelect === 'Fiat') {
                setDecimalPlace(rate)
                setDealtDecimals(dealt)
            }
            else {
                setDealtDecimals(rate)
                setDecimalPlace(dealt)
            }
        }    
    },[tradingPair, action, askingTermsSelect])

    useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(action === 'Buy') {
            getAssetAvailableInventory(tradingPair1).then(res => setAssetInventory(res))
        }
        else {
            if(!['CAD', 'USD', 'EUR', 'GBP'].includes(tradingPair2)){
                getAssetAvailableInventory(tradingPair2).then(res => setAssetInventory(res))
            }
            else {
                setAssetInventory(0)
                setAssetOut(null)
                setInventoryLowWarning(false)
            }
        }
    }, [tradingPair, action])

    useEffect(() => {
        console.log(assetInventory);
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(assetInventory){

            if(action === 'Buy') {
                // inventory out is related to Amount To Trade
                if(parseFloat(amountToTrade) > assetInventory) {
                    setInventoryLowWarning(true)
                    // alert('NOT ENOUGHT INVENTORY')
                }
                else{
                    setInventoryLowWarning(false)
                }
            }
            else {
                if(!['CAD', 'USD', 'EUR', 'GBP'].includes(tradingPair2)){
                    // we look at amount for inventory out 
                    if(parseFloat(amount) > assetInventory) {
                        setInventoryLowWarning(true)
                        // alert('NOT ENOUGHT INVENTORY')
                    }
                    else{
                        setInventoryLowWarning(false)
                    }
                }
                
            }
        }
        
    }, [amount, amountToTrade, action])
    useEffect(() => {
        // CALC CORY COMMISSION
        let commissionAmount = coryCommissionAmt
        if(action == 'Sell'){
            commissionAmount = askingTermsSelect == 'Fiat' ? parseFloat(amountToTrade) * parseFloat(coryCommission)/100:parseFloat(amount) * parseFloat(coryCommission)/100
            console.log('SEll comm', amount);
            setCoryCommissionAmt(commissionAmount)
        }
        else {
            commissionAmount = askingTermsSelect == 'Fiat' ? parseFloat(amountToTrade) * parseFloat(coryCommission)/100:parseFloat(spotRate) * parseFloat(coryCommission)/100
            console.log('BUY comm result', commissionAmount);
            console.log('BUY comm', spotRate);
            console.log('BUY comm comm', coryCommission);
            setCoryCommissionAmt(commissionAmount)
        }
    }, [coryCommission, coryCommissionAmt, amount, action,spotRate, askingTermsSelect, amountToTrade])

    useEffect(() => {
        // TODO: CALC ADDITIONAL COMMISSION 
        let addComm = 0.35 * parseFloat(markup) * parseFloat(amount) / (parseFloat(spotRate) + parseFloat(markup))
        console.log('additional comm', addComm);
        setAdditionalCommission(addComm)
    }, [markup, spotRate, amount, askingTermsSelect])

    useEffect(() => {
        let total = parseFloat(coryCommissionAmt) + parseFloat(additionalCommission)
        console.log('parseFloat(coryCommission)', parseFloat(coryCommissionAmt));
        console.log('parseFloat(additionalCommission)', parseFloat(additionalCommission));
        console.log('total commission', total);
        setTotalCommission(total)
    }, [coryCommission, additionalCommission, askingTermsSelect, coryCommissionAmt])
    const getAssetAvailableInventory = async(asset) => {
        setAssetOut(asset)
        let result = await wacService.getWACByAsset(asset)
        if(result){
            return parseFloat(result.assetQuantity)
        }
        else {
            return 0
        }
    }
    const handleEnableFeeChangeDialog = () => {
        setFeeChangeAlert(true)
    }
    const handleEnableCommissionChangeDialog = () => {
        setCommissionChangeAlert(true)
    }
    const handleConfirmEnableFeeChange = () => {
        setEnableFeeChange(true)
        setFeeChangeAlert(false)
    }
    const handleConfirmEnableCommissionChange = () => {
        setEnableCommissionChange(true)
        setCommissionChangeAlert(false)
    }
    const handleDateTimeChange = (date) => {
        // Handle the change event here
        console.log(date);
        setTradeTimestamp(date)
    };
    async function submittedTradeTicket(res) {
        let result = await tradeService.createTradeTicket(res);
        // console.log('result', result);
        if(result.trade_id){
            // trigger increment function 
            let id_result = await tradeService.updateTradeId();
            // @TODO: handle error
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Creating trade successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating trade failed!")
            return false
        }
    }

    const handleChangeFeeType = () => {
        if(feeType === 0) {
            setFeeType(1)
        }
        else {
            setFeeType(0)
        }
    }
    const extractCurrency = (value) => {
        // Regular expression to match the last word in the string
        const currencyRegex = /\b[A-Z]+\b$/;
        
        // Match the regex against the value string
        const match = value.match(currencyRegex);
        
        // If there's a match, return the currency code, otherwise return null
        return match ? match[0] : null;
    };
    const extractNumber = (value) => {
        // Regular expression to match the numeric part of the string
        const numberRegex = /^[\d,\.]+/;
        
        // Match the regex against the value string
        const match = value.match(numberRegex);
        
        if (match) {
            // Remove commas from the numeric part
            const numericString = match[0].replace(/,/g, '');
            
            // Convert to float
            const numericValue = parseFloat(numericString);
            
            return numericValue;
        } else {
            return null;
        }
    };
    const handleSubmitAtlas = async(tradeId, ccyPurchased, spotRate, ccyDeposited) => {
        //setSubmitAtlasLoading(true)
        if (!clientName) {// && !clientOptions.map(option => option.value).includes(clientName)) {
            setClientNameError('Invalid client name. Please select from the dropdown.');
            return;
        }
        if (clientName) {
            let atlasCustomerID = customerData.customers[0]? customerData.customers[0].id : null
            let atlasAccountID = accountData.accounts[0]? accountData.accounts[0].id : null
            // LC Clients
            if(atlasCustomerID && !atlasAccountID){
                let resAccount = await createNewAtlasTradingAccount({variables: {
                    customer_id: atlasCustomerID,
                    ref_id: `GL:${clientId}`,
                    provider_id: GL_TRADING_ACCOUNT_PROVIDER_ID,
                    name: clientName + ' GL Trade Account'
                }})
                atlasAccountID = resAccount.data.insert_accounts_one.id
            }
            console.log('atlasClientID', atlasCustomerID);
            try {
                let trade_side = action.toUpperCase()
                let result = await postTradeToAtlas({variables: {
                    commission: Number(spread) / 100,
                    customer_id: atlasCustomerID,
                    destination_currency: extractCurrency(ccyPurchased),
                    fees: 0,
                    provider_id: '1318dac3-c5e8-4b1b-8779-e8ffb61ed043', //PROVIDER_ID[liquidityProvider],
                    quantity: extractNumber(ccyDeposited),
                    price: spotRate,
                    ref_id: tradeId,
                    side: trade_side,
                    source_currency: extractCurrency(ccyDeposited),
                    type: 'MARKET',
                    value: extractNumber(ccyPurchased),
                    status: 'COMPLETED',
                    account_from_id: trade_side == 'BUY'? GL_TRADING_ACCOUNT_ID: atlasAccountID,
                    account_to_id: trade_side == 'BUY'? atlasAccountID: GL_TRADING_ACCOUNT_ID,
                    category: TRADING_CATEGORY,
                    transaction_updated_at: tradeTimestamp.toISOString(),
                    transaction_created_at: tradeTimestamp.toISOString(),
                }})
                console.log(result);
            }
            catch(err){
                console.log(err)
            }
        }
    }
    const handleSubmit = async(event) => {
        // console.log('handleSubmit running');
        setSubmitLoading(true)
        event.preventDefault();
        let count = update + 1
        // console.log('clientName', clientName);
        // console.log('clientOptions', clientOptions);
        if (!clientName) {// && !clientOptions.map(option => option.value).includes(clientName)) {
            setClientNameError('Invalid client name. Please select from the dropdown.');
            return;
        }

        if (clientName) {
            let ccyDeposited = 
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 4, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 4}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal :5}) + ' ' + tradingPair.split('/')[0]
                    : action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal: 4, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 4}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
            let ccyPurchased =
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal : 4, minimumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal:4}) + ' ' + tradingPair.split('/')[1]
                    : action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]] ? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal : 4, minimumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal : 4}) + ' ' + tradingPair.split('/')[1]
            let curDate = new Date((new Date()).toLocaleDateString("en-US", {timeZone: "America/New_York"})).toISOString()
            let formattedSpotRate = askingTermsSelect === 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let formattedDealtRate = askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let execAmt = action === 'Buy'? ccyPurchased:ccyDeposited
                // console.log('ccyDeposited', ccyDeposited);
            // console.log('ccyPurchased', ccyPurchased);
            // console.log('curDate', curDate);
            console.log('Running submittedTradeTicket');
            // let newestTradeIdCheck = await getNewestTradeIdBeforeSubmission()
            let result = await submittedTradeTicket({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: tradeTimestamp.toISOString(),
                totalCommission: totalCommission,
                markup: markup,
                sender: curUser.nickname,
                liquidityProvider,
                amt: execAmt,
                execPrice,
                platform,
                orderId,
                source: 'ADAM',
                pushToExecChat: !notPushToExecChat
            });
            //await handleSubmitAtlas(tradeTicketID, ccyPurchased, parseFloat(spotRate), ccyDeposited)
            if(result){
                setShowPreview(false)
                setUpdate(count)
                setAskingTermsSelect('Fiat')
                setClientName('')
                setTradeTicketID('')
                setTradingPair('BTC/USD')
                setAction('Buy')
                setAmount('0')
                setAmountToTrade('0')
                setSpread('0')
                setDynamicSpread('0')
                setCoryCommission('0')
                setSpotRate('0')
                setClientDealtRate('0')
                setEnableFeeChange(false)
                setMarkup('0')
                setLiquidityProvider('')
                setExecPrice('0')
                setPlatform('')
                setOrderId('')
                setClient({})
                setNotPushToExecChat(false)
                setTradeTimestamp(dayjs())
            }
            setSubmitLoading(false)
          // ...Reset state...
        }
        setSubmitLoading(false)
    };
    useEffect(() => {
        console.log('inventoryLowWarning', inventoryLowWarning);
        console.log('assetOut', assetOut);
        console.log('assetInventory', assetInventory);
        console.log('amountToTrade', amountToTrade);
        console.log('check', (inventoryLowWarning || (assetOut && assetInventory <= 0)));
    },[inventoryLowWarning, assetOut, assetInventory, amountToTrade])
    // useEffect(() => {
    //     console.log(enableCommissionChange);
    // }, [enableCommissionChange])
    // console.log('tradingPair', tradingPair);
    return (
        <Paper style={{ padding: 5}}>
            <div>
                <Stack spacing={1}>
                <Stack direction={{xs: 'column', sm: 'row'}} style={{justifyContent: 'space-between'}}>
                    <Stack style={{
                        width: {
                            xs: '100%', // 100% width on xs screens
                            sm: '70%',  // 30% width on sm screens and above
                        }
                    }} spacing={1}>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Direction</div>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={action}
                                onChange={(e) => setAction(e.target.value)} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                <MenuItem value={'Buy'}>Buy</MenuItem>
                                <MenuItem value={'Sell'}>Sell</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Entity</div>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={entity}
                                onChange={(e) => setEntity(e.target.value)} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                <MenuItem value={'SDM'}>SDM</MenuItem>
                                <MenuItem value={'GL'}>GL</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client</div>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={clientOptions}
                                sx={{width: '100%'}}
                                size='small'
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{textAlign: 'left' }} {...props} key={option.value}>
                                        {option.label}
                                    </Box>
                                )}
                                filterOptions={(options, { inputValue }) => {
                                    const inputValueLowerCase = inputValue.trim().toLowerCase();
                                    let result = options.filter(
                                        (option) => option.label.trim().toLowerCase().includes(inputValueLowerCase)
                                        );
                                    // Sort the results to have exact matches at the beginning
                                    result.sort((a, b) => {
                                        const aStartsWith = a.label.toLowerCase().startsWith(inputValueLowerCase) ? -1 : 0;
                                        const bStartsWith = b.label.toLowerCase().startsWith(inputValueLowerCase) ? -1 : 0;

                                        return aStartsWith - bStartsWith;
                                    });
                                    return result
                                }}
                                value={clientName}
                                // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                    // console.log('v', v)
                                    setClient(v)
                                    setClientName(v.label)
                                    setClientId(v.value)
                                }}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Client Name"/>}
                            />
                        </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>is asking in terms of</div>
                            <FormControl sx={{ m: 1, minWidth: 90 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={askingTermsSelect}
                                onChange={(e) => setAskingTermsSelect(e.target.value)} 
                                required
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                {askingTermsOptions.map((option) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                        <FormControl sx={{ m: 1, minWidth: 140 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={tradingPairOptions}
                                sx={{width: '100%'}}
                                size='small'
                                value={tradingPair}
                                freeSolo
                                filterOptions={(options, state) => {
                                    // custom filter function; checks if the *beginning*
                                    // of each option (Trading Pair) matches the user input:
                                    const inputValue = state.inputValue.toLowerCase();
                                    const inputLength = inputValue.length;
                                    return options.filter((option) => {
                                        // slicing the optionLabel up to the length of the inputValue
                                        const optionLabel = option.label.toLowerCase().slice(0, inputLength);
                                        // and comparing the two strings.
                                        return optionLabel === inputValue;
                                    });
                                }}
                                onInputChange={(e, v) => {
                                    // console.log('input', e);
                                    // console.log('input', v);
                                    setTradingPair(v.toUpperCase())}}
                                // onChange={(e, v) => {
                                //     console.log(v)
                                //     setTradingPair(v ? v.value : '')
                                // }} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Assets Pair"/>}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount</div>
                        <FormControl sx={{ m: 1, minWidth: 160 }}>
                            <TextField 
                                value={amount} 
                                onChange={(e) => {
                                    // console.log('e', e)
                                    setAmount(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[1]:tradingPair.split('/')[0]
                                }}
                                sx={{width: '100%'}}
                                required
                                size='small'
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spread/Fee (%)</div>
                        <FormControl sx={{ m: 1, minWidth: 100 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : feeType === 0 ? spread: dynamicSpread} 
                                readOnly={spreadLoading} 
                                onChange={(e) => setSpread(e.target.value)} 
                                error={spreadError}
                                helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={!enableFeeChange}
                            />
                        </FormControl>
                        <Button onClick={() => handleEnableFeeChangeDialog()}>Change Fee</Button>
                        {/* <Button onClick={() => handleChangeFeeType()} disabled={true} color="secondary">{feeType === 0 ? 'Use Dynamic Fee' : 'Use Default'}</Button> */}
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Cory Commission (%)</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : coryCommission} 
                                readOnly={spreadLoading} 
                                onChange={(e) => setCoryCommission(e.target.value)} 
                                error={spreadError}
                                helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={!enableCommissionChange}
                            />
                        </FormControl>
                        <Button onClick={() => handleEnableCommissionChangeDialog()}>Change Commission</Button>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spot Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spotRate} 
                                onChange={(e) => {
                                    setSpotRate(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client Dealt Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={parseFloat(clientDealtRate).toFixed(dealtDecimals)}
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount to Trade</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(amountToTrade).toFixed(decimalPlace)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Commission Amount</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(coryCommissionAmt).toFixed(8)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    //endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]//action === 'Sell'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Markup</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(markup)} 
                                required
                                onChange={(e) => {
                                    setMarkup(e.target.value)
                                }}
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    //endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Additional Comm</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(additionalCommission).toFixed(8)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    //endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]//action === 'Sell'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Total Comm</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(totalCommission).toFixed(8)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    //endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]//action === 'Sell'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trade ID</div>
                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                            <TextField 
                                value={tradeTicketID}
                                disabled
                                required
                                size='small'
                                sx={{width: '100%'}}
                                // fullWidth
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack style={{
                        width: {
                            xs: '100%', // 100% width on xs screens
                            sm: '30%',  // 30% width on sm screens and above
                        }
                    }} spacing={1}>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>LP</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={liquidityProvider}
                                    onChange={(e) => setLiquidityProvider(e.target.value)} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'cross'}>cross</MenuItem>
                                    <MenuItem value={'aquanow'}>Aquanow</MenuItem>
                                    <MenuItem value={'b2c2'}>B2C2</MenuItem>
                                    <MenuItem value={'cumberland'}>Cumberland</MenuItem>
                                    <MenuItem value={'dvchain'}>DV Chain</MenuItem>
                                    <MenuItem value={'enigma'}>Enigma</MenuItem>
                                    <MenuItem value={'flowtraders'}>Flow Traders</MenuItem>
                                    <MenuItem value={'galaxy'}>Galaxy</MenuItem>
                                    <MenuItem value={'mobilum'}>Mobilum</MenuItem>
                                    <MenuItem value={'nonco'}>Nonco</MenuItem>
                                    <MenuItem value={'wintermute'}>Wintermute</MenuItem>
                                    <MenuItem value={'falconx'}>FalconX</MenuItem>
                                    <MenuItem value={'stillmandigital'}>Stillman Digital</MenuItem>
                                    <MenuItem value={'flowdesk'}>Flowdesk</MenuItem>
                                    <MenuItem value={'crypto.com'}>Crypto.com</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Amt</div>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <TextField 
                                    value={
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5})
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5})
                                        }
                                    disabled
                                    required
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair.split('/')[0]
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Price</div>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <TextField 
                                    value={execPrice} 
                                    onChange={(e) => {
                                        setExecPrice(e.target.value)
                                    }}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Platform</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={platform}
                                    onChange={(e) => setPlatform(e.target.value)} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'SDMLive'}>SDMLive</MenuItem>
                                    <MenuItem value={'AXE'}>AXE</MenuItem>
                                    <MenuItem value={'Telegram'}>Telegram</MenuItem>
                                    <MenuItem value={'Signal'}>Signal</MenuItem>
                                    <MenuItem value={'Whatsapp'}>Whatsapp</MenuItem>
                                    <MenuItem value={'Email'}>Email</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>OrderId</div>
                            <FormControl sx={{ m: 1, minWidth: 170 }}>
                                <TextField 
                                    value={orderId} 
                                    onChange={(e) => {
                                        setOrderId(e.target.value)
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack> 
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Date</div>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker 
                                        // label="Basic date time picker" 
                                        slotProps={{ textField: { size: 'small', hiddenLabel: true } }}
                                        onChange={handleDateTimeChange}
                                        value={tradeTimestamp}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>
                    </Stack>
                    </Stack>
                    <Stack sx={{ m: 10,justifyContent: 'center', alignItems: 'center',display: {
                            xs: 'none', 
                            sm: 'flex'
                        }}}>
                        <FormControlLabel control={<Checkbox checked={notPushToExecChat} onChange={handleNotPushExecChange}/>} label="Don't push trade to Exec"/>
                    </Stack>
                    {assetOut? <Stack sx={{ m: 2}}>
                        Available Inventory for {assetOut}: {assetInventory} 
                    </Stack>: null }
                    <Stack sx={{ justifyContent: 'center', display: {
                            sm: 'none'
                        }}}>
                        <FormControlLabel control={<Checkbox checked={notPushToExecChat} onChange={handleNotPushExecChange}/>} label="Don't push trade to Exec"/>
                    </Stack>
                    <Stack sx={{ m: 2, display: {
                            // xs: 'block', 
                            sm: 'none'
                        }}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                    <Stack sx={{ m: 2}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                </Stack>
            </div>
            {showTable ? 
                <Stack style={{marginTop: '5px'}}>
                    <TradeTicketsTable
                        accessToken={token}
                        tradesData={rawTrade}
                        loading={loading}
                        curUser={curUser}
                        update={update}
                        setUpdate={setUpdate}
                        setErrorMsg={setErrorMsg}
                        setIsError={setIsError}
                        setSuccessMsg={setSuccessMsg}
                        setIsSuccess={setIsSuccess}
                        paginationProps={paginationProps}
                        clientOptions={clientOptions && clientOptions}
                    />
                </Stack>
                :null}
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    {successMsg}
                </Alert>
            </Snackbar>
            {/* <Snackbar open={inventoryLowWarning || (assetOut && assetInventory <= 0)} autoHideDuration={2000}>
                <Alert severity="error">
                    You don't have enought inventory to submit this trade!
                </Alert>
            </Snackbar> */}
            <TradePreviewDialog 
                open={showPreview} 
                submitTicket={(e) => handleSubmit(e)}
                handleClose={() => setShowPreview(false)}
                clientName={clientName}
                tradeTicketID={tradeTicketID}
                tradingPair={tradingPair}
                action={action}
                spread={spread}
                amount={amount}
                spotRate={spotRate}
                clientDealtRate={clientDealtRate}
                amountToTrade={amountToTrade}
                askingTermsSelect={askingTermsSelect}
                date={moment(tradeTimestamp.toISOString()).format('DD-MMM-YY')}
                decimalPlace={decimalPlace}
                dealtDecimal={dealtDecimals}
                loading={submitLoading}
                commission={totalCommission}
                liquidityProvider={liquidityProvider}
                execAmt={askingTermsSelect == 'Fiat'? 
                parseFloat(amountToTrade)
                :parseFloat(amount)}
                execPrice={execPrice}
                platform={platform}
                orderId={orderId}
            />
            <Modal
                open={feeChangeAlert}
                onClose={() => setFeeChangeAlert(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                        Confirm to change fee
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    </Typography>
                    <Stack direction={'row'} spacing={1} sx={{justifyContent: 'center'}}>
                        <Button variant='contained' color='primary' onClick={() =>  handleConfirmEnableFeeChange()}>Confirm</Button>
                        <Button variant='contained' color='error' onClick={() =>  setFeeChangeAlert(false)}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
            <Modal
                open={commissionChangeAlert}
                onClose={() => setFeeChangeAlert(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                        Confirm to change commission
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    </Typography>
                    <Stack direction={'row'} spacing={1} sx={{justifyContent: 'center'}}>
                        <Button variant='contained' color='primary' onClick={() =>  handleConfirmEnableCommissionChange()}>Confirm</Button>
                        <Button variant='contained' color='error' onClick={() =>  setCommissionChangeAlert(false)}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
        </Paper>
    );
}

// export default TradeTicketGenerator;
export default withAuthenticationRequired(TradeTicketGenerator, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});