import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { ClientService } from '../../../api/ClientService';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function AddClientnameModal({ adamClient, clientnameAdded, accessToken }) {
    const [show, setShow] = useState(false);
    const [clientNames, setClientNames] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const clientService = new ClientService(accessToken);
  
    const { register, handleSubmit, reset } = useForm();
  
    const onClientnameAddSubmit = (data) => {
      data.adamClientId = adamClient.id;
      clientService.addClientnameTask(data).then((response) => {
        clientnameAdded(response);
        setShow(false);
        reset();
      });
    };

    const handleDelete = (clientName) => {
        const confirmed = window.confirm(`Confirm that you want to delete this alternate client name: "${clientName.client_name}"`);
        if (confirmed) {
          // console.log('Deleting Clientname with id: ', clientName.id);
          clientService.deleteClientnameTask(clientName.id)
            .then((response) => {
              setShow(false);
              reset();
              const updatedClientNames = clientNames.filter((a_clientName) => a_clientName.id !== clientName.id);
              setClientNames(updatedClientNames);
            });
        }
    };
  
    // Set & update the Clientnames list
    React.useEffect(() => {
        if (adamClient.adamClientnames.length > 0) {
            // console.log('adamClient.adamClientnames', adamClient.adamClientnames);
            setClientNames(adamClient.adamClientnames);
        }
    //   clientService
    //     .getClientnames()
    //     .then((clientNames) => {
    //       setClientNames(clientNames);
    //     })
        // .catch((error) => console.log(error));
    }, []);
  
    let new_clientname = {};
    new_clientname.client_name = '';
    new_clientname.name_source = '';
  
    return (
      <>
        <Button size="small" variant="contained" color="secondary" onClick={handleShow}>
          Add Name
        </Button>
        <Dialog open={show} onClose={handleClose}>
          <DialogContent>
            <form onSubmit={handleSubmit(onClientnameAddSubmit)}>
              <div className="row" style={{ margin: "5px" }}>
                <div className="form-group col-md-6">
                  <label style={{ fontWeight: "bold" }}>Client Name:</label>
                  <p>{adamClient.clientPrimaryName}</p>
                </div>
              </div>
              <div className="row" style={{ margin: "5px" }}>
                <div className="form-group col-md-6">
                  <label htmlFor="name_source" style={{ fontWeight: "bold" }}>
                    Source of Name
                  </label>
                  <select
                    {...register("name_source")}
                    className="form-control"
                    name="name_source"
                    id="name_source"
                  >
                    <option value="">Select...</option>
                    <option value="TradeTicketName">Trade Ticket Name</option>
                    <option value="Fireblocks">Fireblocks</option>
                    <option value="BCB">BCB</option>
                    <option value="ConnectFirst">ConnectFirst</option>
                    <option value="Finclusive">Finclusive</option>
                    <option value="Fortress">Fortress</option>
                    <option value="Openpayd">Openpayd</option>
                    <option value="PrimeTrust">PrimeTrust</option>
                    <option value="Arival">Arival</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{ margin: "5px" }}>
                <div className="form-group col-md-6">
                  <label htmlFor="client_name" style={{ fontWeight: "bold" }}>
                    Alternate Name for Client
                  </label>
                  <input
                    {...register("client_name")}
                    type="text"
                    className="form-control"
                    defaultValue={new_clientname.client_name}
                    name="client_name"
                    id="client_name"
                    placeholder="Alternate Name for Client"
                  />
                </div>
              </div>
              <div className="btncenter">
                <input type="submit" className="btn btn-danger" />
              </div>
            </form>
            <div>
                {clientNames.map((clientName) => (
                    <div key={clientName.id} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            <p>Name: {clientName.client_name}<br/>Source: {clientName.name_source}</p>
                        </div>
                        <div>
                            <button onClick={() => handleDelete(clientName)}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
}

export default AddClientnameModal;
