import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '../../../api/ClientService';
import CircularProgress from '@mui/material/CircularProgress';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Grid } from '@mui/material';
import ClientReportTable from './ClientReportTable';
import ClientReportBalanceTable from './ClientReportBalanceTable';
import ClientReportBalanceAdjustmentTable from './ClientReportBalanceAdjustmentTable';

function ClientDetails({token}) {
    let { clientCopperId } = useParams();
    console.log('ClientDetails() clientCopperId', clientCopperId);
    const clientService = new ClientService(token);

    const [ client, setClient ] = useState(null);
    const [ tables, setTables ] = useState([]);
    const [ balances, setBalances ] = useState({});
    const [ balanceOffsets, setBalanceOffsets ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ filesDownloading, setFilesDownloading ] = useState({})
  
    // Get client details
    useEffect(() => {
      clientService.getClient(clientCopperId)
        .then((client) => {
          setClient(client);
        })
        .then(() => clientService.getClientReportData(clientCopperId))
        .then(data => {
          setTables(data.tableData);
          setBalances(data.balanceData);
          setBalanceOffsets(data.balanceOffsets);

          setLoading(false);
        })
        .catch((error) => console.log(error));
    }, [clientCopperId]);

    const downloadReport = async fileType => {
      setFilesDownloading(oldFilesDownloading => ({ ...oldFilesDownloading, [fileType]: true }))

      try {
        const resBlob = await clientService.getClientReportBlob(clientCopperId, fileType)
        const blobURL = window.URL.createObjectURL(resBlob)

        const tmpLink = window.document.createElement('a')

        tmpLink.href = blobURL
        tmpLink.download = `report.${fileType}`
        tmpLink.click()

        window.URL.revokeObjectURL(blobURL)
      }
      catch(err) {
        console.error(err)
      }

      setFilesDownloading(oldFilesDownloading => ({ ...oldFilesDownloading, [fileType]: false }))
    }
  
    if (loading) {
      return <CircularProgress />;
    }

    return (
      <>
        <div>
          <h2>{client.clientPrimaryName}</h2>
          <p>{client.clientCopperId}</p>
          <p>{client.entityForClient}</p>
        </div>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item>
            <button disabled={filesDownloading['csv']} onClick={() => downloadReport('csv')}>{filesDownloading['csv'] ? 'Loading...' : 'Generate Report (CSV)'}</button>
          </Grid>
          <Grid item>
            <button disabled={filesDownloading['xlsx']} onClick={() => downloadReport('xlsx')}>{filesDownloading['xlsx'] ? 'Loading...' : 'Generate Report (XLSX)'}</button>
          </Grid>
          <Grid item>
            <button disabled={filesDownloading['pdf']} onClick={() => downloadReport('pdf')}>{filesDownloading['pdf'] ? 'Loading...' : 'Generate Report (PDF)'}</button>
          </Grid>
        </Grid>
        <br/>
        <ClientReportBalanceTable label='Total Volume Transacted' balanceMap={balances.totalVolumes} />
        <br/>
        <ClientReportBalanceTable label='Balance' balanceMap={balances.balances} />
        <br/>
        {
          tables.map(tableData => (
            <>
              <ClientReportTable tableData={tableData} />
              <br />
            </>
          ))
        }
        <ClientReportBalanceAdjustmentTable balanceOffsets={balanceOffsets} />
      </>
    );
}

export default withAuthenticationRequired(ClientDetails, {
  onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});