import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import ClientMapping from './ClientMapping';
import Fireblocks from './Fireblocks';
import PrimeTrust from './PrimeTrust';
import BCB from './BCB';
import ConnectFirst from './ConnectFirst';
import Finclusive from './Finclusive';
import Fortress from './Fortress';
import Openpayd from './Openpayd';
import Talos from './Talos';
import FireblocksWalletMapping from './FireblocksWalletMapping';
import TradeTicketGenerator from './TradeTicketGenerator';
import VoiceChatLPExecution from './VoiceChatLPExecution';
import TradingPairManager from './TradingPairsAdmin';
import TradeCommission from './TradeCommission';
import TradeReport from './TradeReport';
import Balances from './Balances';
import UploadFortressCSV from './UploadFortressCSV';
import WAC from './WAC';
import MergedTrades from './MergedTrades';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const BANKS = ['PrimeTrust', 'BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd']

const Adam = ({curUser, token}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedBank, setSelectedBank] = useState(0)
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        // Perform actions based on the selected menu item
        console.log('Selected menu item:', index);
        setSelectedBank(index)
        handleMenuClose();
    };

    return (
        <div>
            <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label="Client Mapping"/>
                <Tab label="Fireblocks Wallet Mapping"/>
                <Tab label="Fireblocks"/>
                <Tab 
                    label={'Banks-' + BANKS[selectedBank]} onClick={handleMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"/>
                <Tab label="Talos"/>
                <Tab label="Trade Ticket Generator"/>
                {/* <Tab 
                    label={
                        <div>
                            <Link to="/justtradeticketgenerator">
                                <IconButton size="small">
                                    <LaunchIcon />
                                </IconButton>
                            </Link>
                        </div>
                    }
                    aria-label="Just Trade Ticket Generator" 
                /> */}
                <Tab label="Trading Pairs"/>
                {/* <Tab label="Voice Chat LP Execution"/> */}
                <Tab label="Client Commission"/>
                <Tab label="WAC"/>
                <Tab
                    label="Trade Report"
                />
                <Tab
                    label="Balances"
                />
                <Tab
                    label="UploadFortressCSV"
                />
                {/* <Tab
                    label="Merged"
                /> */}
                {/* <Tab label="PrimeTrust"/>
                <Tab label="BCB"/>
                <Tab label="ConnectFirst"/>
                <Tab label="Finclusive"/>
                <Tab label="Fortress"/>
                <Tab label="Openpayd"/>
                <Tab label="Talos"/>
                <Tab label="Trade Ticket Generator"/>
                <Tab label="Bank Reconciliation"/> */}
                {/* <Tab
                    label="Tab 3"
                    onClick={handleMenuOpen}
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                /> */}
            </Tabs>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleMenuItemClick(0)}>PrimeTrust</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(1)}>BCB</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(2)}>ConnectFirst</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(3)}>Finclusive</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(4)}>Fortress</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(5)}>Openpayd</MenuItem>
            </Menu>

            {/* Render the content based on the selected tab */}
            {tabIndex === 0 && <ClientMapping accessToken={token} />}
            {tabIndex === 1 && <FireblocksWalletMapping accessToken={token} />}
            {tabIndex === 2 && <Fireblocks accessToken={token} />}
            {tabIndex === 3 && selectedBank === 0 && <PrimeTrust accessToken={token} />}
            {tabIndex === 3 && selectedBank === 1 && <BCB accessToken={token} />}
            {tabIndex === 3 && selectedBank === 2 && <ConnectFirst accessToken={token} />}
            {tabIndex === 3 && selectedBank === 3 && <Finclusive accessToken={token} /> }
            {tabIndex === 3 && selectedBank === 4 && <Fortress accessToken={token} />}
            {tabIndex === 3 && selectedBank === 5 && <Openpayd accessToken={token} />}
            {tabIndex === 4 && <Talos accessToken={token} />}
            {tabIndex === 5 && <MergedTrades curUser={curUser} token={token} showTable={true} />}
            {/* {tabIndex === 5 && <TradeTicketGenerator
                curUser={curUser} showTable={true} token={token}
            />} */}
            {tabIndex === 6 && <TradingPairManager accessToken={token} />}
            {/* {tabIndex === 8 && <VoiceChatLPExecution curUser={curUser} token={token} showTable={true}/>} */}
            {tabIndex === 7 && <TradeCommission curUser={curUser} token={token} />}
            {tabIndex === 8 && <WAC curUser={curUser} token={token} />}
            {tabIndex === 9 && <TradeReport />}
            {/* TODO: REPLACE FIREBLOCKS WITH BALANCE COMPONENT */}
            {tabIndex === 10 && <Balances accessToken={token}/>}
            {tabIndex === 11 && <UploadFortressCSV accessToken={token}/>}
            
        </div>
    );
};

export default withAuthenticationRequired(Adam, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});
