import React, { useRef } from 'react';
import { Button, Paper, Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const StyledPaper = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  height: '150px',
  border: '2px dashed #cccccc'
});

function FileUpload(props) {
  const fileInputRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      props.onFileDropped(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".csv"
  });

  return (
    <div>
      <StyledPaper {...getRootProps()} variant="outlined">
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag & drop a file here, or click to select one
        </Typography>
      </StyledPaper>
      <br />
      <Button 
        variant="contained" 
        color="primary"
        onClick={props.onUpload}
      >
        Upload
      </Button>
    </div>
  );
}

export default FileUpload;
