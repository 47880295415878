import React, { useState, useEffect } from 'react';
import RawConnectFirstData from './RawConnectFirstData';
import { ConnectFirstService } from '../../../api/ConnectFirst';
import { ClientService } from '../../../api/ClientService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import CADOpex from './CADOpex';
import CADTrading from './CADTrading';
import USDData from './USDData';
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'

function ConnectFirst({ accessToken }) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)

    const connectFirstService = new ConnectFirstService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const accountIDPerTab = [
        undefined,
        '899-1319-723112387318',
        '899-369-723112119208'
    ]

    useEffect(() => {
        setLoading(true)

        connectFirstService.getAllConnectFirst(paginationProps.pageSize, paginationProps.pageIndex, accountIDPerTab[activeTab]).then(rawCF => {
            setRawData(rawCF.data.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)));
            paginationProps.setTotalItemCount(rawCF.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize, activeTab])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        paginationProps.setPageIndex(0)
    }

    const updateRawConnectFirstEntry = (transactionID, newData) => {

        // Use ID instead of index directly to handle filtered transaction lists
        const index = rawData.findIndex(transaction => transaction.id === transactionID)

        setRawData(oldRawCF => [
            ...oldRawCF.slice(0, index),
            newData,
            ...oldRawCF.slice(index + 1)
        ])
    }

    return (
        // <div>
        //     <RawConnectFirstData
        //         rawData={rawData}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="CAD Trading" />
                <Tab label="CAD Opex" />
                {/* <Tab label="USD" /> */}
            </Tabs>
            <div style={{width: '100%'}}>
                <RawConnectFirstData rawData={rawData} loading={loading || clientsLoading} clients={clients} 
                    setConnectFirstClient={(connectFirstID, clientCopperID) => connectFirstService.setConnectFirstClient(connectFirstID, clientCopperID)}
                    updateRawConnectFirstEntry={updateRawConnectFirstEntry} paginationProps={paginationProps} />
            </div>
        </Box>
    );
}

export default ConnectFirst;