/**
 * TalosService Service
 */
export class TradeTicketService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async createTradeTicket(data){        
        const response = await fetch(this.API_URL + '/gltrade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({data: data})
        })
        return response.json();
    }

    async updateTrade(data) {
        console.log(data);
        const response = await fetch(
            this.API_URL + '/updatetrade',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                },
                body: JSON.stringify({data: data})
            }
        );
        return response.json();
    }

    async updateTradeId() {
        const response = await fetch(
            this.API_URL + '/ticketgencountincrement',
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async getAllTradeTicket(pageSize, pageNum){
        const response = await fetch(
            this.API_URL + `/sdmtrades?size=${pageSize}&page=${pageNum}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async deleteTradeTicket(data){
        const response = await fetch(this.API_URL + '/deletetrade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({data: data})
        })
        return response.json();
    }

    async getAllVoiceChatTradeTicket(){
        const response = await fetch(
            this.API_URL + '/allvoicechattrades',
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async createVoiceChatTradeTicket(data){        
        const response = await fetch(this.API_URL + '/voicechattrade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({trade: data})
        })
        return response.json();
    }
}