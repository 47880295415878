import React, { useState, useEffect } from 'react';
import RawTalosData from './RawTalosData';
import RawTalosCustomerData from './RawTalosCustomerData';
import { TalosService } from '../../../api/TalosService';
import { Box, Tabs, Tab } from '@mui/material';
import usePagination from '../../../hooks/usePagination';

function Talos({ accessToken }) {

    const [rawData, setRawData] = useState([])
    const [rawDataCustomer, setRawDataCustomer] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0);
    const talosService = new TalosService(accessToken);

    const paginationProps = usePagination()

    useEffect(() => {
        setLoading(true)

        if(activeTab === 0)
            talosService.getAllTalos(paginationProps.pageSize, paginationProps.pageIndex).then(rawCF => {
                let sortedData = rawCF.data.sort((a, b) => new Date(b.transact_time)- new Date(a.transact_time))

                setRawData(sortedData)
                paginationProps.setTotalItemCount(rawCF.totalItems)

                setLoading(false)
            }).catch(err => {
                console.log(err);
                setLoading(false)
            });

        else
            talosService.getCustomerTalos(paginationProps.pageSize, paginationProps.pageIndex).then(rawCF => {
                let sortedData = rawCF.data.sort((a, b) => new Date(b.transact_time)- new Date(a.transact_time))

                setRawDataCustomer(sortedData)
                paginationProps.setTotalItemCount(rawCF.totalItems)

                setLoading(false)
            }).catch(err => {
                console.log(err);
                setLoading(false)
            });
    }, [paginationProps.pageSize, paginationProps.pageIndex, activeTab]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
        paginationProps.setPageIndex(0)
    }

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="SDM Inc" />
                <Tab label="Customer" />
            </Tabs>
            <div style={{width: '100%'}}>
                {activeTab === 0 && <RawTalosData rawData={rawData} loading={loading} paginationProps={paginationProps} />}
                {activeTab === 1 && <RawTalosCustomerData rawData={rawDataCustomer} loading={loading} paginationProps={paginationProps} />}
            </div>
        </Box>
    );

}

export default Talos;