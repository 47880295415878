import React, { useState, useEffect } from 'react';
import RawFortressData from './RawFortressData';
import { FortressService } from '../../../api/FortressService';
import { ClientService } from '../../../api/ClientService'
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'

function Fortress({ accessToken }) {

    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)

    const [update, setUpdate] = useState(0);

    const fortressService = new FortressService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    useEffect(() => {
        setLoading(true)

        fortressService.getAllFortress(paginationProps.pageSize, paginationProps.pageIndex).then(rawCF => {
            setRawData(rawCF.data.sort((a, b) => Date.parse(b.data_created) - Date.parse(a.data_created)));
            paginationProps.setTotalItemCount(rawCF.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageSize, paginationProps.pageIndex]);

    const updateRawDataEntry = (transactionID, newData) => {
        const index = rawData.findIndex(transaction => transaction.id === transactionID)

        setRawData(oldRawData => [
            ...oldRawData.slice(0, index),
            newData,
            ...oldRawData.slice(index + 1)
        ])
    }

    return (
        <div>
            <RawFortressData
                rawData={rawData}
                loading={loading || clientsLoading}
                clients={clients}
                update={update}
                setUpdate={setUpdate}
                setFortressClient={(fortressTxId, clientCopperID) => fortressService.setFortressClient(fortressTxId, clientCopperID)}
                updateRawDataEntry={updateRawDataEntry}
                paginationProps={paginationProps}
            />
        </div>
    );
}

export default Fortress;