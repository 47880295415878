import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { BalancesService } from '../../../api/BalancesService';
function AllBalances({token, customerBalance, customerLoading}) {
    console.log(customerLoading);
    let balanceService = new BalancesService(token)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getBalances()
    },[])
    const getBalances = async() => {
        setLoading(true)
        console.log(balanceService);
        let res = await balanceService.getBalances()
        console.log(res)
        setLoading(false)
        setData(res)
    }
    return (
        <div>
            <TableContainer component={Paper}>
                {loading ||  customerLoading ? <LinearProgress color="success"/> : null}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Assets</TableCell>
                        <TableCell align="left">Total Units (FB)</TableCell>
                        <TableCell align="left">Total Units (LP)</TableCell>
                        <TableCell align="left">Total Units (Customer)</TableCell>
                        <TableCell align="left">SDM Inc. Inventory</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data && customerBalance  && data.assets.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row}
                        </TableCell>
                        <TableCell align="left">{data.fireblocksBal[row] ? data.fireblocksBal[row].available.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}</TableCell>
                        <TableCell align="left">{data.lpBalance[row] ? data.lpBalance[row].balance.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}</TableCell>
                        <TableCell align="left">{customerBalance[row] ? (-1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) : 0}</TableCell>
                        <TableCell align="left">
                            {data.fireblocksBal[row] ? 
                                    customerBalance[row] ? 
                                        data.lpBalance[row] ? (data.fireblocksBal[row].available +  data.lpBalance[row].balance + -1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                                        : (data.fireblocksBal[row].available + -1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                                        : data.lpBalance[row] ? (data.fireblocksBal[row].available +  data.lpBalance[row].balance).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})
                                        : (data.fireblocksBal[row].available).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})
                                    : customerBalance[row] ? (customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}
                        </TableCell>
                        {/* <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amount : row.in.amount}</TableCell>
                        <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amountUSD : row.in.amountUSD}</TableCell>
                        <TableCell align="left">{row.date}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default AllBalances;