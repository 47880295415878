import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { Button, Tooltip, tooltipClasses, styled, TextField, FormControl, Autocomplete, Box, Select, MenuItem, InputAdornment, FilledInput, Stack } from '@mui/material';
import { TradeTicketService } from '../../../api/TradeTicketService';
import SimpleTablePagination from '../../../components/SimpleTablePagination';
// import EditTradeTicketDialog from './EditTradeTicketDialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DELETE_TRADE } from '../../../api/atlasQueries';
import { useMutation } from '@apollo/client';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
}));

function TradeTicketsTable({ accessToken, tradesData, loading, curUser, update, setUpdate, setErrorMsg, setIsError, setSuccessMsg, setIsSuccess, paginationProps, clientOptions }) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTradeDetailInfo, setOpenTradeDetailInfo] = useState(false);
    const [tradeData, setTradeData] = useState();
    const [openSelectedTooltip, setOpenSelectedTooltip] = useState('');
    // EDIT TRADE
    const [openEditTrade, setOpenEditTrade] = useState(false);
    const [tradeEditingIndex, setTradeEditingIndex] = useState(-1);
    const [tradeEditing, setTradeEditing] = useState();
    const [delTradeFromAtlas] = useMutation(DELETE_TRADE);
    let tradeTicketService = new TradeTicketService(accessToken);
    // console.log(clientOptions);
    const handleOpenEditTradeDialog = (trade, index) => {
        console.log(trade, index);
        setOpenEditTrade(true);
        setTradeEditingIndex(index)
        setTradeEditing(trade)
    }

    const handleCloseEditTradeDialog = () => {
        setOpenEditTrade(false);
        setTradeEditingIndex(-1)
        setTradeEditing()
    }

    const handleOpenTradeDetailDialog = (row, index) => {
        setTradeData(row)
        setCurTxIndex(index)
        setOpenTradeDetailInfo(true)
    }

    const handleCloseTradeDetailDialog = () => {
        setCurTxIndex(-1)
        setOpenTradeDetailInfo(false)
    }
    const handleOpenSeletedDeleteToolTip = (id) => {
        setOpenSelectedTooltip(id)
    }
    const handleCloseSelectedToolTip = () => {
        setOpenSelectedTooltip('')
    }
    const onConfirmTradeDeletion = async (trade) => {
        // console.log('curUser.nickname', curUser.nickname);
        // console.log('trade', trade);
        let count = update + 1
        let data = {
            username: curUser.nickname,
            trade: trade
        }
        let result = await tradeTicketService.deleteTradeTicket(data)
        try {
            let atlasRes = await delTradeFromAtlas({variables: {
                ref_id: trade.trade_id,
            }})
            console.log(atlasRes);
        }
        catch(err){
            console.log(err)
        }
        // console.log('result', result);
        if(result == 1){
            handleCloseSelectedToolTip()
            setUpdate(count)
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Deleting trade successfully!")
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Deleting trade failed!")
        }
    }

    const updateTrade = (value, type) => {
        let trade = {...tradeEditing}
        trade[type] = value
        setTradeEditing(trade)
    }
    // TODO: ONLY UPDATE DATE FOR NOW, WILL ADD THE OTHER PARAMS LATER IF REQUESTED
    const handleTradeEditUpdate = async() => {
        let count = update + 1
        // console.log(dayjs(tradeEditing.date).format());
        let data = {
            username: curUser.nickname,
            trade: tradeEditing
        }
        let result = await tradeTicketService.updateTrade(data)
        console.log('result', result);
        if(result[0] === 1){
            handleCloseEditTradeDialog()
            setUpdate(count)
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Updating trade successfully!")
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Updating trade failed!")
        }

    }
    const tradeDateFormatter = (date) => {
        if(!date){
            return ''
        }
        const parsedDate = new Date(date);
        const torontoTimeZone = 'America/Toronto';
        // console.log(item.date);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: torontoTimeZone,
        }).format(parsedDate);
        // console.log(formattedDate);
        return formattedDate
    }
    const RawTradeDataRow = (rawTradeDataRow, index) => {
        // console.log('rawPrimetrustDataRow', rawPrimetrustDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {openEditTrade && tradeEditingIndex === index ? 
                    <>
                        <TableCell>
                            {/* {tradeEditing.trade_id} */}
                            <TextField
                                id="outlined-controlled"
                                value={tradeEditing.trade_id}
                                variant="standard" 
                                onChange={(event) => {
                                    updateTrade(event.target.value, 'trade_id');
                                }}
                                sx={{ minWidth: 220 }}
                            />
                        </TableCell>
                        <TableCell align='left'>
                            {/* {tradeEditing.date} */}
                            {/* <FormControl > */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={dayjs(rawTradeDataRow.date)}
                                        onChange={(newValue) => updateTrade(newValue, 'date')}
                                        variant="standard" 
                                        sx={{ minWidth: 220 }}
                                    />
                                </LocalizationProvider>
                            {/* </FormControl> */}
                            {/* <TextField
                                id="outlined-controlled"
                                label="Controlled"
                                value={name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setName(event.target.value);
                                }}
                            /> */}
                        </TableCell>
                        <TableCell align='left'>
                            {tradeEditing.client}
                            {/* <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clientOptions}
                                    sx={{width: '100%'}}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{textAlign: 'left' }} {...props}>
                                            {option.label}
                                        </Box>
                                    )}
                                    value={tradeEditing.client}
                                    // getOptionLabel={(option) => option.label}
                                    onChange={(e, v) => {
                                        // console.log('v', v)
                                        updateTrade(v.label, 'client')
                                        updateTrade(v.value, 'client_id')
                                    }}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder="Client Name" variant="standard" />}
                                />
                            </FormControl> */}
                        </TableCell>
                        <TableCell align='left'>
                            {tradeEditing.type}
                            {/* <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tradeEditing.type}
                                onChange={(e) => updateTrade(e.target.value, 'type')} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                                variant="standard" 
                            >
                                <MenuItem value={'Buy'}>Buy</MenuItem>
                                <MenuItem value={'Sell'}>Sell</MenuItem>
                            </Select>
                        </FormControl> */}
                        </TableCell>
                        <TableCell align='left'>
                            {tradeEditing.ccy_deposited}
                            {/* <TextField
                                id="filled-adornment-weight"
                                aria-describedby="filled-weight-helper-text"
                                value={tradeEditing.ccy_deposited.split(" ")[0]}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{tradeEditing.ccy_deposited.split(" ")[1]}</InputAdornment>,
                                }}
                                onChange={(e) => updateTrade(e.target.value + " " + tradeEditing.ccy_deposited.split(" ")[1], 'ccy_deposited')}
                                variant="standard" 
                            /> */}
                        </TableCell>
                        <TableCell align='left'>
                            {tradeEditing.ccy_purchased}
                            {/* <TextField
                                id="filled-adornment-weight"
                                aria-describedby="filled-weight-helper-text"
                                value={tradeEditing.ccy_deposited.split(" ")[0]}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{tradeEditing.ccy_purchased.split(" ")[1]}</InputAdornment>,
                                }}
                                onChange={(e) => updateTrade(e.target.value + " " + tradeEditing.ccy_purchased.split(" ")[1], 'ccy_purchased')}
                                variant="standard" 
                            /> */}
                        </TableCell>
                        <TableCell align='left'>
                            {tradeEditing.spot_price}
                            {/* <TextField
                                id="filled-adornment-weight"
                                aria-describedby="filled-weight-helper-text"
                                value={tradeEditing.spot_price}
                                onChange={(e) => updateTrade(e.target.value, 'spot_price')}
                                variant="standard" 
                            /> */}
                        </TableCell>
                        <TableCell align='left'>{tradeEditing.dealt_rate}</TableCell>
                        <TableCell align='left'>{tradeEditing.asset_pair}</TableCell>
                        <TableCell align='left'>{tradeEditing.sender}</TableCell>
                        <TableCell align='left'>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpenTradeDetailDialog(rawTradeDataRow, index)}
                            >
                                <InfoIcon color="success"/>
                            </IconButton>
                        </TableCell>
                        <TableCell align='left'>
                            <Stack direction={'row'}>
                                <Button onClick={() => handleTradeEditUpdate()}>Save</Button>
                                <Button onClick={() => handleCloseEditTradeDialog()}>Cancel</Button>
                            </Stack>
                            
                        </TableCell>
                    </>
                    :<>
                        <TableCell>{rawTradeDataRow.trade_id}</TableCell>
                        <TableCell align='left'>{tradeDateFormatter(rawTradeDataRow.createdAt)}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.client}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.type}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.ccy_deposited}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.ccy_purchased}</TableCell>
                        {/* <TableCell align='left'>{rawTradeDataRow.trade}</TableCell> */}
                        <TableCell align='left'>{rawTradeDataRow.spot_price}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.dealt_rate}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.asset_pair}</TableCell>
                        <TableCell align='left'>{rawTradeDataRow.sender}</TableCell>
                        <TableCell align='left'>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpenTradeDetailDialog(rawTradeDataRow, index)}
                            >
                                <InfoIcon color="success"/>
                            </IconButton>
                        </TableCell>
                        <TableCell align='left'>
                            <Button onClick={() => handleOpenEditTradeDialog(rawTradeDataRow, index)}>Edit</Button>
                        </TableCell>
                </>}
                <HtmlTooltip
                    placement="left"
                    open={openSelectedTooltip == rawTradeDataRow.id}
                    arrow
                    title={
                        <React.Fragment>
                            <div style={{fontSize: '14px'}}>Are you sure you want to delete this trade?</div>
                            <Button sx={{m: 1}} color='info' size="small" variant="outlined" onClick={() => handleCloseSelectedToolTip()}>Cancel</Button>
                            <Button sx={{m: 1}} size="small" variant="contained" color='error' onClick={() => onConfirmTradeDeletion(rawTradeDataRow)}>OK</Button>
                        </React.Fragment>
                    }
                >
                    <TableCell align='right'>
                        <Button color='error' onClick={() => handleOpenSeletedDeleteToolTip(rawTradeDataRow.id)}>Delete</Button>
                    </TableCell>
                </HtmlTooltip>
            </TableRow>
        )
    }
    const tradeTicketTable = tradesData
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        // .map((item) => {
        //     const parsedDate = new Date(item.date);
        //     // console.log(item.date);
        //     const formattedDate = new Intl.DateTimeFormat('en-US', {
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //     }).format(parsedDate);
        //     console.log(formattedDate);
        //     return {
        //         ...item,
        //         date: formattedDate,
        //     };
        // })
        .map((rawTradeDataRow,index) => RawTradeDataRow(rawTradeDataRow,index))
    if (tradesData.length === 0) return null
    return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 400, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Trade Id</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Client</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Trade</TableCell>
                                <TableCell align="left">Spot Price</TableCell>
                                <TableCell>Dealt Rate</TableCell>
                                <TableCell>Asset Pair</TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell align="left"/>
                                <TableCell align="left"/>
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tradeTicketTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTradeDetailInfo} 
                closeDialog={handleCloseTradeDetailDialog}
                data={tradeData}
                title={"Trade detail"}
            />
            {/* <EditTradeTicketDialog open={openEditTrade} handleClose={handleCloseEditTradeDialog} trade={tradeEditing}/> */}
        </div>
    );
}

export default TradeTicketsTable;