import { useState } from 'react'

const usePagination = (defaultPageSize = 50) => {
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(defaultPageSize)
    const [totalItemCount, setTotalItemCount] = useState(0)

    return {
        pageIndex,
        pageSize,
        setPageIndex,
        setPageSize: newPageSize => setPageSize(oldPageSize => {
            setPageIndex(oldPageIndex => Math.floor(oldPageIndex * oldPageSize / newPageSize))
            return newPageSize
        }),
        setPageSizeRaw: setPageSize,
        totalItemCount,
        setTotalItemCount
    }
}

export default usePagination