import React, { useState, useEffect } from 'react';
import RawFireblocksData from './RawFireblocksData';
import { FireblocksService } from '../../../api/FireblocksService';
import { ClientService } from '../../../api/ClientService'
import CircularProgress from '@mui/material/CircularProgress';
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'

function Fireblocks({accessToken}) {

    const [rawFireblocks, setRawFireblocks] = useState([])
    const [loading, setLoading] = useState(true)

    const fireblocksService = new FireblocksService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const updateRawFireblocksEntry = (transactionID, newData) => {
        const index = rawFireblocks.findIndex(transaction => transaction.id === transactionID)

        setRawFireblocks(oldRawFireblocks => [
            ...oldRawFireblocks.slice(0, index),
            newData,
            ...oldRawFireblocks.slice(index + 1)
        ])
    }

    useEffect(() => {
        setLoading(true)

        fireblocksService.getAllFireblocks(paginationProps.pageSize, paginationProps.pageIndex).then(rawFireblocks => {
            setRawFireblocks(rawFireblocks.data)
            paginationProps.setTotalItemCount(rawFireblocks.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [paginationProps.pageIndex, paginationProps.pageSize])
    return (
        <div>
            {loading || clientsLoading? 
            <CircularProgress/> 
            :<RawFireblocksData
                rawFireblocks={rawFireblocks}
                updateRawFireblocksEntry={updateRawFireblocksEntry}
                clients={clients}
                setFireblocksClient={(fireblocksID, clientCopperID) => fireblocksService.setFireblocksClient(fireblocksID, clientCopperID)}
                paginationProps={paginationProps}
            ></RawFireblocksData>}
        </div>
    );
}

export default Fireblocks;